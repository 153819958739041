import { DataSource } from "@angular/cdk/table";
import { File } from "../Model/File";
import { BehaviorSubject, Observable, of } from "rxjs";
import { FileService } from "../Service/file.service";
import { CollectionViewer } from "@angular/cdk/collections";
import { catchError, finalize } from "rxjs/operators";

export class FileDataSource implements DataSource<File> {
  public filesSubject = new BehaviorSubject<File[]>([]);
  public lengthSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(private file: FileService) {}

  connect(collectionViewer: CollectionViewer): Observable<File[]> {
    return this.filesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.filesSubject.complete();
    this.loadingSubject.complete();
  }

  loadFile(perPage, page, search) {
    const idxLast = perPage * page;
    const idxFirst = idxLast - perPage;
    this.loadingSubject.next(true);

    this.file
      .getFiles(search)
      .pipe(
        catchError(() => of([])),
        finalize(() => {
          this.loadingSubject.next(false);
        })
      )
      .subscribe((result) => {
        this.filesSubject.next(result["hydra:member"].slice(idxFirst, idxLast));
        this.lengthSubject.next(result["hydra:totalItems"]);
      });
  }
}
