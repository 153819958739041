import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-contract-create-dialog-prewiev-add-sign-tags",
  templateUrl: "./contract-create-dialog-prewiev-add-sign-tags.component.html",
  styleUrls: ["./contract-create-dialog-prewiev-add-sign-tags.component.scss"],
})
export class ContractCreateDialogPrewievAddSignTagsComponent implements OnInit {
  constructor() {}

  @Input() contractForm;
  @Input() previewForm;
  ngOnInit() {
    console.log("ContractCreateDialogPrewievAddSignTagsComponent", {
      contractForm: this.contractForm,
      previewForm: this.previewForm,
    });
  }
}
