import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { map, share } from "rxjs/operators";
import * as moment from "moment";
import { environment } from "../../environments/environment";
import { LoadingService } from "./loading.service";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private _protocol: string = environment.api_protocol;
  private _host: string = environment.api_host;
  private _endpoint: string = environment.api_endpoint;
  private _version: string = environment.api_version;
  private language = "fr_FR";

  constructor(
    private _http: HttpClient,
    private _loading: LoadingService,
    private cookieService: CookieService
  ) {}

  public get(ressource: string, params: {} = {}): Observable<any> {
    this._loading.addTask();
    const searchParams = this.prepareParams(params);

    const observable = this._http
      .get(this.getUrl() + "/" + ressource, {
        params: searchParams,
        headers: new HttpHeaders({
          // 'accept': 'application/json'
        }),
        // observe: 'response'
      })
      .pipe(map((data) => <any>this.processData(data)))
      .pipe(share());

    this.finishTaskLoading(observable);
    return observable;
  }

  public getWithFormParam(ressource: string, params: {} = {}): Observable<any> {
    this._loading.addTask();
    const searchParams = new HttpParams();

    // for (let name in params) {
    //   params = params.append('name, data[name]);
    // }
    //
    // return params;

    const observable = this._http
      .get(this.getUrl() + "/" + ressource, {
        params: searchParams,
        headers: new HttpHeaders({
          accept: "application/json",
        }),
        // observe: 'response'
      })
      .pipe(map((data) => <any>this.processData(data)))
      .pipe(share());

    this.finishTaskLoading(observable);
    return observable;
  }

  public put(
    ressource: string,
    object: any,
    ignore_properties: string[] = [],
    keep_collection: string[] = [],
    keep_object: string[] = []
  ): Observable<HttpResponse<any>> {
    this._loading.addTask();
    let data: any = Object.assign({}, object);

    // Clear property we won't send
    for (const property of ignore_properties) {
      delete data[property];
    }
    data = this.convertDateField(data);

    const observable = this._http
      .put(this.getUrl() + "/" + ressource, data, { observe: "response" })
      .pipe(share());

    this.finishTaskLoading(observable);
    return observable;
  }

  public postRow(
    ressource: string,
    object: any,
    ignore_properties: string[] = [],
    keep_collection: string[] = [],
    keep_object: string[] = [],
    headers = {}
  ): Observable<HttpResponse<any>> {
    // Convert some field for API compliance
    this._loading.addTask();
    let data: any = Object.assign({}, object);

    // Clear property we won't send
    for (const property of ignore_properties) {
      delete data[property];
    }
    data = this.convertDateField(data);

    let observable;
    if (headers !== {}) {
      observable = this._http
        .post(this.getUrl() + "/" + ressource, data, {
          observe: "response",
          headers: headers,
        })
        .pipe(share());
    } else {
      observable = this._http
        .post(this.getUrl() + "/" + ressource, data, { observe: "response" })
        .pipe(share());
    }

    this.finishTaskLoading(observable);
    return observable;
  }

  public patchRow(
    ressource: string,
    object: any,
    ignore_properties: string[] = [],
    keep_collection: string[] = [],
    keep_object: string[] = []
  ): Observable<HttpResponse<any>> {
    this._loading.addTask();

    // Convert some field for API compliance
    let data: any = Object.assign({}, object);

    // Clear property we won't send
    for (const property of ignore_properties) {
      delete data[property];
    }

    data = this.convertDateField(data);

    const observable = this._http
      .patch(this.getUrl() + "/" + ressource, data, { observe: "response" })
      .pipe(share());
    this.finishTaskLoading(observable);
    return observable;
  }

  public deleteRow(
    ressource: string,
    id: number
  ): Observable<HttpResponse<any>> {
    this._loading.addTask();

    const observable = this._http
      .delete(this.getUrl() + "/" + ressource + "/" + id, {
        observe: "response",
      })
      .pipe(share());

    this.finishTaskLoading(observable);
    return observable;
  }

  public login(username: string, password: string) {
    return this.postRow("login", { username: username, password: password });
  }

  public hasRoleFor(url: string): Observable<boolean> {
    this._loading.addTask();
    let params: HttpParams = new HttpParams().set("url", url);
    const observable = this._http
      .get(this.getUrl() + "/user/me/has_role_for", {
        // headers: this.getHeaders(),
        params: params,
      })
      .pipe(map((data) => data["result"]))
      .pipe(share());

    this.finishTaskLoading(observable);
    return observable;
  }

  private processData(
    object: any,
    callback: any = function (object: any) {
      return object;
    }
  ): any {
    if (object instanceof Array) {
      for (let field of object) {
        if (field.hasOwnProperty("createdAt")) {
          field["createdAt"] = this.convertToDate(field["createdAt"]);
        }
        if (field.hasOwnProperty("status")) {
          field["status"]["date"] = this.convertToDate(field["status"]["date"]);
        }
        if (field.hasOwnProperty("creationDate")) {
          field["creationDate"] = this.convertToDate(field["creationDate"]);
        }
      }
    }
    if (object.hasOwnProperty("createdAt")) {
      object["createdAt"] = this.convertToDate(object["createdAt"]);
    }
    if (object.hasOwnProperty("status")) {
      object["status"]["date"] = this.convertToDate(object["status"]["date"]);
    }
    if (object.hasOwnProperty("creationDate")) {
      object["creationDate"] = this.convertToDate(object["creationDate"]);
    }
    return callback(object);
  }

  private convertToDate(date: string): Date | string {
    let format = "YYYY-MM-DD HH:mm ZZ";
    let dateObj = moment(date, format);
    if (dateObj.isValid()) {
      return dateObj.toDate();
    }
    return date;
  }

  private convertDateField(data) {
    for (const property in data) {
      if (data.hasOwnProperty(property) && data[property] instanceof Date) {
        // data[property] = this.dateToString(data[property]);
      } else if (data[property] instanceof Object) {
        this.convertDateField(data[property]);
      }
    }
    return data;
  }

  private prepareParams(data: {}): HttpParams {
    let params: HttpParams = new HttpParams();

    for (let name in data) {
      if (data[name] instanceof Array) {
        for (let param of data[name]) {
          params = params.append(name + "[]", param);
        }
      } else if (name == "order") {
        params = params.append(name + "[creationDate]", data[name]);
      } else {
        params = params.append(name, data[name]);
      }
    }

    return params;
  }

  public getUser() {
    return this.get("users/me");
  }

  private dateToString(date: Date): string {
    return moment(date).format("DD/MM/YYYY HH:mm");
  }

  private getUrl(): string {
    return this._protocol + this._host + this._endpoint + this._version;
  }

  public checkApiStatus() {
    this._loading.addTask();

    const observable = this._http
      .get(this.getUrl() + "/status", {
        observe: "response",
      })
      .pipe(share());

    this.finishTaskLoading(observable);
    return observable;
  }

  public finishTaskLoading(observable: Observable<any>): void {
    observable.subscribe(
      () => this._loading.removeTask(),
      (response) => {
        let message = "";
        if (response.error) {
          if (response.error.message) {
            message = response.error.message;
          } else {
            message = response.error;
          }
        } else {
          if (response.message) {
            message = response.message;
          } else {
            message = response;
          }
        }
        if (typeof message === "string") {
          // this._message.add({severity: 'error', summary: 'Error', detail: message });
        }
        this._loading.removeTask();
      }
    );
  }

  public postImage(image: string) {
    let formData: FormData = new FormData();
    formData.append("file", image);
    return this._http
      .post(this.getUrl() + "/images", formData, {})
      .pipe(share());
  }

  public postFileImportMission(file: string, type: string) {
    let formData: FormData = new FormData();
    formData.append("file", file, "file");
    return this._http
      .post(this.getUrl() + "/missions/import", formData, {
        headers: new HttpHeaders({
          // 'Content-Type': ''
        }),
      })
      .pipe(share());
  }

  // public postFile(ressource: string, object) {
  //     const header = new HttpHeaders({
  //         'X-AUTH-TOKEN': this.getApiToken()
  //     });
  //     return this._http.post(this.getUrl() + '/' + ressource, object, { headers: header, responseType: 'blob' }).pipe(share());
  // }
  //
  public getBlob(url) {
    return this._http.get(this.getUrl() + "/" + url, { responseType: "blob" });
  }

  public createContractBigFiles(
    subject,
    recipients,
    files,
    signType,
    key,
    isPdfTag = false,
    requireAttachments = false,
    eidasTimestamp = false,
    isCertified = false,
    isHandwrittenSignature = false
  ) {
    const formData: FormData = new FormData();

    formData.append("subject", subject);
    formData.append("signType", signType);
    formData.append("recipients", JSON.stringify(recipients));
    formData.append("isPdfTag", JSON.stringify(isPdfTag));
    formData.append("requireAttachments", JSON.stringify(requireAttachments));
    formData.append("eidasTimestamp", JSON.stringify(eidasTimestamp));
    formData.append("isCertified", JSON.stringify(isCertified));
    formData.append("isHandwrittenSignature", JSON.stringify(isHandwrittenSignature));

    console.log("files", files);
    files.forEach((file) => {
      formData.append(file.content.name, file.content, file.content.name);
    });

    return this._http.post(this.getUrl() + "/contracts/create/big", formData, {
      headers: { "x-auth-token": key },
    });
  }

  public createChainContract(data, key) {
    const formData: FormData = new FormData();
    formData.append("data", JSON.stringify(data));
    return this._http.post(
      this.getUrl() + "/contracts/create/chain",
      formData,
      {
        headers: { "x-auth-token": key },
      }
    );
  }

  public saveLogoCompany(company, data) {
    return this._http.post(
      this.getUrl() + "/companies/" + company.id + "/logo",
      data
    );
  }

  public requestCustomCM(method, url, data = null) {
    const customCMUrl =
      environment.api_customCM_protocol +
      environment.api_customCM_host +
      environment.api_customCM_endpoint +
      "/";
    if (method === "GET") {
      return this._http.get(customCMUrl + url);
    }
    if (method === "POST") {
      return this._http.post(customCMUrl + url, data);
    }
  }

  public createFile(files, key) {
    const formData: FormData = new FormData();
    files.forEach((file) => {
      formData.append(file.content.name, file.content, file.content.name);
    });

    return this._http.post(this.getUrl() + "/file/create", formData, {
      headers: { "x-auth-token": key },
    });
  }

  public requestSendInBlue(data) {
    return this._http.get(
      this.getUrl() +
        "/send-in-blue/" +
        data.contractId +
        "/" +
        data.signatoryId,
      { responseType: "json" }
    );
  }

  public getHashAPI() {
    return this._http.get(
      this.getUrl() +
      '/hash/'
    );
  }

  public deleteHashAPI(id: string) {
    return this._http.get(
      this.getUrl() +
      '/hash/delete/' +
      id
    );
  }

  public getExistingUserDirectory(email: string) {
    return this._http.get(
      this.getUrl() +
      '/get/user/directory/' +
      email
    );
  }

}
