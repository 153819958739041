import {DataSource} from "@angular/cdk/table";
import {BehaviorSubject, Observable, of} from "rxjs";
import {CollectionViewer} from "@angular/cdk/collections";
import {catchError, finalize} from "rxjs/operators";
import {User} from "../Model/User";
import {UserService} from "../Service/user.service";

export class UserDataSource implements DataSource<User> {

    public userSubject = new BehaviorSubject<User[]>([]);
    public lengthSubject = new BehaviorSubject<number>(0);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private _user: UserService) {}

    connect(collectionViewer: CollectionViewer): Observable<User[]> {
        return this.userSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.userSubject.complete();
        this.loadingSubject.complete();
    }

    loadUser(filter = {}, search, sort = '',
                sortDirection = 'asc', pageIndex, pageSize) {

        this.loadingSubject.next(true);

        this._user.getUsers(filter, search, sort, sortDirection,
            pageIndex, pageSize).pipe(
            catchError(() => of([])),
            finalize(() => {
                    this.loadingSubject.next(false);
                }
            ))
            .subscribe(result =>  {
                const users = result['hydra:member'];
                users.forEach((user) => {
                    user.isTrial = true;
                    const userFeature = user.userPaidFeatures.find((userPaidFeature) => {
                      return userPaidFeature.feature.id === 7;
                    });
                    if (userFeature) {
                      user.isTrial = userFeature.enabledAt === null;
                    }
                });
                this.userSubject.next(users);
                this.lengthSubject.next(result['hydra:totalItems']);
            });
    }
}
