import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {AuthService} from "./auth.service";
import {Observable, of, Subject} from 'rxjs';
import {map, share} from "rxjs/operators";
import {User} from "../Model/User";
import {Cacheable, CacheBuster} from "ngx-cacheable";

const cacheBuster$ = new Subject<void>();

const CACHE_PARAM = {
  maxCacheCount: 10,
  cacheBusterObserver: cacheBuster$

};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user = null;

  constructor(
      private _api: ApiService,
      private _auth: AuthService,
  ) {
    this._auth.onLogout.subscribe((event) => {
      this.user = null;
    });
  }

  public getUser(refresh = false) {
    if (!refresh) {
      if (!this._auth.isAuthenticated()) {
        return of(null);
      }
      if (this.user) {
        return of(this.user);
      }
    }

    let obser = this._api.getUser().pipe(share());
    obser.subscribe(
        (user) => {
          this.user = user;
        }
    );
    return obser;
  }

  public cleanUser(){
    this.user = null;
  }

  @CacheBuster({cacheBusterNotifier: cacheBuster$})
  public saveUser(user: User) {
    let data = Object.assign({}, user);
    if (!data.company) {
      data.company = null;
    }
    if (data.company instanceof Object) {
      data.company = data.company['@id'];
    }
    if (data.id) {
      return this._api.put('users/' + data.id, data);
    } else {
      delete data.id;
      return this._api.postRow('users', data);
    }
  }

  @Cacheable(CACHE_PARAM)
  public getUsers(filters = {}, search = '', sort = null, sortDirection = null, page = 1, pageSize = 50) {
    if (page <= 0) {
      page = 1;
    }
    filters['page'] = page;
    filters['itemsPerPage'] = pageSize;
    if (sort) {
      filters['order[' + sort + ']'] = sortDirection;
    }
    filters['search'] = search;
    return this._api.get('users', filters);
  }

  @Cacheable(CACHE_PARAM)
  public getAllUsers(filters = {}, search = '', sort = null, sortDirection = null) {
    if (sort) {
      filters['order[' + sort + ']'] = sortDirection;
    }
    filters['search'] = search;
    filters['itemsPerPage'] = 99999;
    return this._api.get('users', filters);
  }

  @CacheBuster({cacheBusterNotifier: cacheBuster$})
  public disableUser(user: User) {
    return this._api.put('users/' + user.id + '/disable', {});
  }

  @CacheBuster({cacheBusterNotifier: cacheBuster$})
  public enableUser(user: User) {
    return this._api.put('users/' + user.id + '/enable', {});
  }

  @Cacheable(CACHE_PARAM)
  public getAvatar(url) {
    return this._api.get(url.replace('/api/', ''));
  }

  public toogleFeature(feature) {
    const featureCopy = Object.assign({}, feature);
    if (featureCopy.feature instanceof Object) {
      featureCopy.feature = featureCopy.feature['@id'];
    }
    return this._api.put('user_paid_features/' + feature.id, featureCopy);
  }

  public setFeatureCallBackUrl(feature) {
    const featureCopy = Object.assign({}, feature);
    if (featureCopy.feature instanceof Object) {
      featureCopy.feature = featureCopy.feature['@id'];
    }
    return this._api.postRow('user_paid_features/' + feature.id  + '/callbackurl', featureCopy);
  }

  public getSmsLog(user: User, from: Date, to: Date) {
    return this._api.get('sms_logs', {
      'contract.sender': user.username,
      'contract.creationDate[after]': from.toDateString(),
      'contract.creationDate[before]': to.toDateString()
    });
  }

  public getEmailLog(user: User, from: Date, to: Date) {
    return this._api.get('email_logs', {
      'contract.sender': user.username,
      'contract.creationDate[after]': from.toDateString(),
      'contract.creationDate[before]': to.toDateString()
    });
  }

  @CacheBuster({cacheBusterNotifier: cacheBuster$})
  public deleteUser(user: User) {
    return this._api.deleteRow('users', user.id);
  }

  @CacheBuster({cacheBusterNotifier: cacheBuster$})
  public changeMyPassword(data) {
    return this._api.postRow('me/change-password', data);
  }

  @CacheBuster({cacheBusterNotifier: cacheBuster$})
  public getUserEntrust(email) {
    return this._api.get('user_entrust?email=' + email);
  }
}
