import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-contract-create-dialog-document-prefill',
  templateUrl: './contract-create-dialog-document-prefill.component.html',
  styleUrls: ['./contract-create-dialog-document-prefill.component.scss']
})
export class ContractCreateDialogDocumentPrefillComponent implements OnInit {

  @Input() contractForm;
  @ViewChild('fileInput',  {static: false}) fileInput;

  signTypes = [
    {
      label: 'Simple',
      value: 0
    },
    {
      label: 'Avancée',
      value: 1
    },
    {
      label: 'Qualifiée',
      value: 2
    },
    {
      label: 'Qualifiée à distance',
      value: 3
    }
  ];

  constructor() { }

  ngOnInit() {
  }

  onFileChange(event) {
    this.addFiles(event.target.files);
  }

  onFileDropped(files) {
    this.addFiles(files);
  }

  addFiles(files) {
    this.contractForm.controls.template.setValue(null);
    const control = this.contractForm.controls.attachments as FormArray;
    control.clear();

    Array.from(files).forEach((file) => {
      if (file['type'] !== 'application/pdf' && file['type'].indexOf('word') < 1
        && file['type'].indexOf('png') < 1 && file['type'].indexOf('jpg') < 1 && file['type'].indexOf('jpeg') < 1) {
        this.contractForm.controls.attachments.setErrors({
          type: true
        });
        return;
      }
      const filename = file['name'];
      const fileContent = file as Blob;

      control.push(new FormGroup({
        filename: new FormControl(filename, []),
        content: new FormControl(fileContent, []),
      }));
    });
  }

  onChangeSignType(event) {
    if (event.target.value == 3) {
      this.contractForm.controls.isPdfTag.setValue(true);
    }
  }

  onChangeIsPdfTags() {
    if (this.contractForm.value.signType == 3) {
      this.contractForm.controls.isPdfTag.setValue(true);
    }
  }
}
