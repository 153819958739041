import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    private task = 0;

    constructor() { }

    public isLoading(): boolean {
        return this.task > 0;
    }

    public addTask(): void {
        setTimeout(() => {
            this.task++;
        });
    }

    public removeTask(): void {
        setTimeout(() => {
            this.task--;
        });
    }
}
