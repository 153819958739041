import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FileService } from "../../Service/file.service";
import { FileDataSource } from "../../DataSource/FileDataSource";
import { MatDialog } from "@angular/material/dialog";
import { FileCreateDialogDocumentComponent } from "./file-create-dialog-document/file-create-dialog-document.component";
import { File } from "../../Model/File";
import Swal from "sweetalert2";
import { NotificationService } from "../../Service/notification.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";

@Component({
  selector: "app-file",
  templateUrl: "./file.component.html",
  styleUrls: ["./file.component.scss"],
})
export class FileComponent implements OnInit, AfterViewInit {
  dataSource: FileDataSource;
  length: number = 0;

  @ViewChild("search_input", { static: true }) search_input;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  perPage = 50;
  page = 1;
  maxPage;

  constructor(
    private file: FileService,
    public dialog: MatDialog,
    private notification: NotificationService
  ) {}

  ngOnInit() {
    this.dataSource = new FileDataSource(this.file);
  }

  ngAfterViewInit() {
    this.loadFilePage();
    fromEvent(this.search_input.nativeElement, "keyup")
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          // this.paginator.pageIndex = 0;
          this.loadFilePage();
        })
      )
      .subscribe();
  }

  loadFilePage() {
    this.dataSource.loadFile(
      this.perPage,
      this.page,
      this.search_input.nativeElement.value
    );
    this.dataSource.lengthSubject.asObservable().subscribe((length) => {
      this.length = length;
      this.maxPage = Math.ceil(length / this.perPage);
    });
  }

  addFile() {
    this.dialog.open(FileCreateDialogDocumentComponent, {
      disableClose: false,
    });
  }

  copyPath(val: string) {
    const selBox = document.createElement("textarea");
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  deleteFile(file: File = null) {
    Swal.fire({
      title: "Êtes-vous sûr ?",
      text: "Voulez-vous supprimer le fichier " + file.name + " ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        this.file.deleteFile(file).subscribe(() => {
          this.notification.send("success", "Fichier supprimé");
          this.loadFilePage();
        });
      }
    });
  }

  changePage(page) {
    this.page = page;
    this.loadFilePage();
  }

  changePerPage(event) {
    this.perPage = event;
    this.loadFilePage();
  }
}
