import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {User} from '../../../Model/User';
import {UserService} from '../../../Service/user.service';
import {NotificationService} from '../../../Service/notification.service';
import {FileService} from '../../../Service/file.service';

@Component({
  selector: 'app-file-create-dialog-document',
  templateUrl: './file-create-dialog-document.component.html',
  styleUrls: ['./file-create-dialog-document.component.scss']
})
export class FileCreateDialogDocumentComponent implements OnInit, AfterViewInit {

  contractForm: FormGroup = new FormGroup({
    attachments: new FormArray([], [Validators.required]),
    template: new FormControl(null, []),
  });
  @ViewChild('fileInput', {static: false}) fileInput;


  constructor(private file: FileService, public dialogRef: MatDialogRef<FileCreateDialogDocumentComponent>,
              private user: UserService, private notification: NotificationService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  onFileChange(event) {
    this.addFiles(event.target.files);
  }

  onFileDropped(files) {
    this.addFiles(files);
  }

  addFiles(files) {
    this.contractForm.controls.template.setValue(null);
    const control = this.contractForm.controls.attachments as FormArray;
    control.clear();

    Array.from(files).forEach((file) => {
      const filename = file['name'];
      const fileContent = file as Blob;

      control.push(new FormGroup({
        filename: new FormControl(filename, []),
        content: new FormControl(fileContent, []),
      }));
    });
  }

  close() {
    this.dialogRef.close();
  }

  submit() {
    const file = this.contractForm.value;
    this.user.getUser().subscribe((user: User) => {
      this.file.createFile(file, user.apiKey).subscribe(
        (result) => {
          this.notification.send('success', 'Fichier bien créé !');
          this.dialogRef.close();
        },
        (err) => {
          this.notification.send('error', err.error['hydra:description']);
        }
      );
    });
  }
}
