import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {fromEvent, merge} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {UserDataSource} from "../../DataSource/UserDataSource";
import {UserService} from "../../Service/user.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {User} from "../../Model/User";
import {UserDialogComponent} from "./user-dialog/user-dialog.component";
import {UserDialogBillComponent} from './user-dialog-bill/user-dialog-bill.component';
import {NotificationService} from '../../Service/notification.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, AfterViewInit {

  dataSource: UserDataSource;
  displayedColumns: string[] = ['id', 'email', 'name', 'company', 'enabled', 'trial', 'createdAt', 'contracts', 'action'];
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  length: number = 0;

  dialogRef: MatDialogRef<UserDialogComponent>;
  dialogBillRef: MatDialogRef<UserDialogBillComponent>;

  perPage = 10;
  page = 1;
  maxPage;

  @ViewChild('search_input', { static: true }) search_input;

  constructor(
    private _user: UserService,
    public dialog: MatDialog,
    private notification: NotificationService,
  ) { }

  ngOnInit() {
    this.dataSource = new UserDataSource(this._user);
    this.dataSource.lengthSubject.asObservable().subscribe(
      (length) => {
        this.length = length;
        this.maxPage = Math.ceil(length / this.perPage);

      }
    );
  }

  ngAfterViewInit() {
    this.loadUserPage();

    fromEvent(this.search_input.nativeElement,'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.page = 0;
          this.loadUserPage();
        })
      )
      .subscribe();

    // reset the paginator after sorting
    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // on sort or paginate events, load a new page
    // merge(this.sort.sortChange, this.paginator.page)
    //   .pipe(
    //     tap(() => this.loadUserPage())
    //   )
    //   .subscribe();

  }

  loadUserPage() {
    const filter = {};

    if (this.page <= 0) {
      this.page = 1;
    }
    this.dataSource.loadUser(
      filter,
      this.search_input.nativeElement.value,
      '',
      'asc',
      this.page,
      this.perPage
    );
  }

  openUserDialog(user: User = null) {
    this.dialogRef = this.dialog.open(UserDialogComponent, {
      disableClose: true,
      data: user,
      panelClass: 'modal-style',
    });

    this.dialogRef.afterClosed().subscribe(user => {
      if (user) {
        this._user.saveUser(user).subscribe(
          (result) => {
            this.loadUserPage();
            this.notification.send('success', 'Utilisateur édité');
          },
          (err) => {
            this.notification.send('error', err.error['hydra:description']);
          }
        );
      }
    });
  }

  openUserDialogBill(user: User = null) {
    this.dialogBillRef = this.dialog.open(UserDialogBillComponent, {
      disableClose: false,
      data: user,
      panelClass: 'modal-style',
    });
  }

  deleteUser(user: User = null) {
    Swal.fire({
      title: 'Êtes-vous sûr ?',
      text: 'Voulez-vous supprimer l\'utilisateur ' + user.firstname + ' ' + user.lastname + ' ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.isConfirmed) {
        this._user.deleteUser(user).subscribe(() => {
          this.notification.send('success', 'Utilisateur supprimé');
          this.loadUserPage();
        });
      }
    })
  }

  resetSearch() {
    this.page = 1;
    this.loadUserPage();
  }

  changePage(page) {
    this.page = page;
    this.loadUserPage();
  }

  changePerPage(event) {
    this.perPage = event;
    this.resetSearch();
  }
}
