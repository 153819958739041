import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {CompanyDataSource} from '../../DataSource/CompanyDataSource';
import {CompanyService} from '../../Service/company.service';
import {User} from '../../Model/User';
import {CompanyDialogComponent} from './company-dialog/company-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Company} from '../../Model/Company';
import {NotificationService} from '../../Service/notification.service';
import Swal from 'sweetalert2';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit, AfterViewInit {

  dataSource: CompanyDataSource;
  displayedColumns: string[] = ['id', 'name', 'action'];
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  length: number = 0;

  @ViewChild('search_input', { static: true }) search_input;

  dialogRef: MatDialogRef<CompanyDialogComponent>;

  perPage = 10;
  page = 1;
  maxPage;

  constructor(
    private _company: CompanyService,
    public dialog: MatDialog,
    private notification: NotificationService,
  ) { }

  ngOnInit() {
    this.dataSource = new CompanyDataSource(this._company);
    this.dataSource.lengthSubject.asObservable().subscribe(
      (length) => {
        this.length = length;
        this.maxPage = Math.ceil(length / this.perPage);
      }
    );
  }

  ngAfterViewInit() {
    this.loadCompanyPage();

    fromEvent(this.search_input.nativeElement,'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.loadCompanyPage();
        })
      )
      .subscribe();

    // reset the paginator after sorting
    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // on sort or paginate events, load a new page
    // merge(this.sort.sortChange, this.paginator.page)
    //   .pipe(
    //     tap(() => this.loadCompanyPage())
    //   )
    //   .subscribe();
  //
  }

  loadCompanyPage() {
    let filter = {};

    this.dataSource.loadCompany(
      filter,
      this.search_input.nativeElement.value,
      '',
      'asc',
      this.page - 1,
      this.perPage
    );
  }

  openCompanyDialog(user: User = null) {
    this.dialogRef = this.dialog.open(CompanyDialogComponent, {
      disableClose: true,
      data: user,
      panelClass: 'modal-style',
    });

    this.dialogRef.afterClosed().subscribe(company => {
      if (company) {
        this._company.saveCompany(company).subscribe(
          (result) => {
            if (company.logo.length) {
              this._company.saveLogo(company).subscribe(() => {
              });
            }
            this.notification.send('success', 'Entreprise créée');
            this.loadCompanyPage();
          },
          (err) => {
            this.notification.send('error', err.error['hydra:description']);
          }
        );
      }
    });
  }

  deleteCompany(company: Company = null) {
    Swal.fire({
      title: 'Êtes-vous sûr ?',
      text: 'Voulez-vous supprimer l\'entreprise ' + company.name + ' ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler'
    }).then((result) => {
      if (result.isConfirmed) {
        this._company.deleteCompany(company).subscribe(() => {
          this.notification.send('success', 'Entreprise supprimée')
          this.loadCompanyPage();
        });
      }
    });
  }

  resetSearch() {
    this.page = 1;
    this.loadCompanyPage();
  }

  changePage(page) {
    this.page = page;
    this.loadCompanyPage();
  }

  changePerPage(event) {
    this.perPage = event;
    this.resetSearch();
  }
}
