import {DataSource} from "@angular/cdk/table";
import {BehaviorSubject, Observable, of} from "rxjs";
import {CollectionViewer} from "@angular/cdk/collections";
import {catchError, finalize} from "rxjs/operators";
import {Company} from '../Model/Company';
import {CompanyService} from '../Service/company.service';

export class CompanyDataSource implements DataSource<Company> {

  public companiesSubject = new BehaviorSubject<Company[]>([]);
  public lengthSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(private _company: CompanyService) {}

  connect(collectionViewer: CollectionViewer): Observable<Company[]> {
    return this.companiesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.companiesSubject.complete();
    this.loadingSubject.complete();
  }

  loadCompany(filter = {}, search, sort = '',
               sortDirection = 'asc', pageIndex, pageSize) {

    this.loadingSubject.next(true);

    this._company.getCompanies(filter, search, sort, sortDirection,
      pageIndex, pageSize).pipe(
      catchError(() => of([])),
      finalize(() => {
          this.loadingSubject.next(false);
        }
      ))
      .subscribe(result =>  {
        this.companiesSubject.next(result['hydra:member']);
        this.lengthSubject.next(result['hydra:totalItems']);

      });
  }
}
