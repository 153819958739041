import { Injectable } from '@angular/core';
import {Cacheable, CacheBuster} from 'ngx-cacheable';
import {Observable, Subject} from 'rxjs';
import {Company} from '../Model/Company';
import {ApiService} from './api.service';

const cacheBuster$ = new Subject<void>();

const CACHE_PARAM = {
  maxCacheCount: 10,
  cacheBusterObserver: cacheBuster$
};

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private _api: ApiService
  ) { }

  @Cacheable(CACHE_PARAM)
  getCompanies(filters = {}, search = '', sort = null, sortDirection = null, page = 0, pageSize = 50): Observable<Company[]> {
    if (page > 0) {
      filters['page'] = page + 1;
    }
    filters['itemsPerPage'] = pageSize;
    if (sort) {
      filters['order[' + sort + ']'] = sortDirection;
    }
    filters['name'] = search;

    return this._api.get('companies', filters);
  }

  @CacheBuster({cacheBusterNotifier: cacheBuster$})
  saveCompany(company: Company) {
    let data = Object.assign({}, company);
    delete data.logo;
    if (data.id) {
      return this._api.put('companies/' + data.id, data);
    } else {
      delete data.id;
      return this._api.postRow('companies', data);
    }
  }

  @Cacheable(CACHE_PARAM)
  getCompanyForReport(year, filters = {}): Observable<Company[]> {
      filters['order[name]'] = 'asc';
      filters['groups[name]'] = 'withUsers';
      return this._api.get('companies/reporting/' + year, filters);
  }

  @CacheBuster({cacheBusterNotifier: cacheBuster$})
  deleteCompany(company: Company) {
      return this._api.deleteRow('companies', company.id);
  }

  saveLogo(company) {
    const formData: FormData = new FormData();

    const file = company.logo[0];
    formData.append(file.content.name, file.content, file.content.name);
    return this._api.saveLogoCompany(company, formData);
  }
}
