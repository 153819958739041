import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../Service/auth.service";
import {User} from "../../Model/User";
import {UserService} from "../../Service/user.service";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  user: User;

  constructor(
      private _user: UserService,
  ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this._user.getUser(true).subscribe(
        (user) => {
          this.user = user;
        }
    );
  }

  isAdmin(user: User) {
    return user.roles.includes('ROLE_ADMIN') || user.roles.includes('ROLE_SUPER_ADMIN') || user.userAdminArea;
  }

  isSuperAdmin(user) {
    return user.roles.includes('ROLE_SUPER_ADMIN');
  }
}
