import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../Service/auth.service';

@Component({
  selector: 'app-login-email',
  templateUrl: './login-email.component.html',
  styleUrls: ['./login-email.component.scss']
})
export class LoginEmailComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private _auth: AuthService,
    private _router: Router,
  ) { }

  ngOnInit() {
      const token = this.route.snapshot.paramMap.get('token');

      this._auth.loginToken(token).subscribe((result) => {
          this._router.navigate(['/']);
      }, (err) => {
        this._router.navigate(['/']);
      });

  }

}
