import {AfterViewInit, Component, Input, OnInit, ViewChild,} from '@angular/core';
import {ContactService} from '../../../../Service/contact.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationService} from '../../../../Service/notification.service';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../../../../Service/api.service';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {UserService} from '../../../../Service/user.service';
import {UserDirectory} from "../../../../Model/UserDirectory";
import {CountryISO, SearchCountryField} from 'ngx-intl-tel-input';

@Component({
  selector: 'app-contract-create-dialog-contact',
  templateUrl: './contract-create-dialog-contact.component.html',
  styleUrls: [
    './contract-create-dialog-contact.component.scss',
  ],
})
export class ContractCreateDialogContactComponent
  implements OnInit, AfterViewInit {
  @Input() contractForm;

  @ViewChild('contactSearch', { static: false }) contactSearch;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom, CountryISO.France];

  contactForm: FormGroup = new FormGroup({
    gender: new FormControl('Monsieur', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    firstname: new FormControl('', [Validators.required]),
    lastname: new FormControl('', [Validators.required]),
    phoneMobile: new FormControl('', []),
    type: new FormControl('internal', [Validators.required]),
    company: new FormControl('', []),
    signPosition: new FormControl('1', []),
  });
  contacts;

  contactCreationMode = false;
  currentFilter = 'all';
  rangs = [1, 2, 3, 4, 5];

  emailValue: string;
  firstnameValue: string;
  lastnameValue: string;
  phoneValue: string;
  companyValue: string;

  constructor(
    private _contact: ContactService,
    private _notification: NotificationService,
    private  http: HttpClient,
    private api: ApiService,
    private _user: UserService
  ) {}

  ngOnInit() {
    this.getContact();
  }

  ngAfterViewInit() {
    fromEvent(this.contactSearch.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.getContact(this.contactSearch.nativeElement.value);
        })
      )
      .subscribe();
  }

  getContact(search = '', filters = {}) {
    this._contact
      .getContacts(filters, search, null, null, 0, 50)
      .subscribe((contacts) => {
        this.contacts = contacts['hydra:member'];
      });
  }

  addContact(contact) {
    if (!this.isContactSelected(contact)) {
      if (this.contractForm.value.signType == 3) {
        contact.signPosition = this.contractForm.controls.contacts.value.length + 1;
      } else {
        contact.signPosition = 1;
      }
      this.contractForm.controls.contacts.value.push(contact);
    }
  }

  removeContact(contact) {
    this.contractForm.controls.contacts.value.splice(
      this.contractForm.controls.contacts.value.indexOf(contact),
      1
    );

    const ordered = this.contractForm.controls.contacts.value.sort((a, b) => {
      if (a.signPosition > b.signPosition) {
        return 1;
      }
      if (a.signPosition < b.signPosition) {
        return -1;
      }
      return 0;
    });

    ordered.forEach((contact, i) => {
      contact.signPosition = i + 1;
    })

  }

  isContactSelected(contact) {
    const contacts = this.contractForm.controls.contacts.value;
    const result = contacts.filter((item) => {
      return item.id === contact.id;
    });
    return result.length > 0;
  }

  newContactMode(enable = true) {
    this.contactCreationMode = enable;
  }

  toogleFilter(filter) {
    this.currentFilter = filter;
    if (filter === 'all') {
      this.getContact();
    }
    if (filter === 'internal' || filter === 'external') {
      this.getContact(this.contactSearch.nativeElement.value, { type: filter });
    }
  }

  onSubmitContact() {
    const contact = this.contactForm.value;
    if (contact.phoneMobile) {
      contact.phoneMobile = contact.phoneMobile.e164Number;
    }
    this._contact.saveContact(contact).subscribe(
      (result) => {
        this.getContact();
        this.addContact(result.body);
        this._notification.send('success', 'Contact créé et ajouté');
        this.contactForm.reset();
        this.newContactMode(false);
      },
      (err) => {
        this._notification.send('error', err.error['hydra:description']);
      }
    );
  }

  contactComment(value, contact) {
    const contacts = this.contractForm.controls.contacts.value;
    contacts.map((contactItem) => {
      if (contactItem.id === contact.id) {
        contactItem.comment = value;
      }
    });
    this.contractForm.controls.contacts.setValue(contacts);
  }

  contactRang(e, contact) {
    const value = Number(e.target.value);
    const contacts = this.contractForm.controls.contacts.value;
    let lastValue = null;
    contacts.map((contactItem) => {
      if (contactItem.id === contact.id) {
        lastValue = contactItem.signPosition;
        contactItem.signPosition = value;
      }
    });

    if (this.contractForm.value.signType == 3) {
      contacts.map((contactItem) => {
        if (contactItem.signPosition == value) {
          contactItem.signPosition = lastValue;
        }
      });
    }


    this.contractForm.controls.contacts.setValue(contacts);
  }

  autoCompleteContact(email: string) {

    this.api.getExistingUserDirectory(email).subscribe(
      (result: UserDirectory) => {
        if (result.firstname) {
          this.firstnameValue = result.firstname;
        }
        if (result.lastname) {
          this.lastnameValue = result.lastname;
        }
        if (result.phone) {
          this.phoneValue = result.phone;
        }
        if (result.company) {
          this.companyValue = result.company;
        }
      }
    );


  }

}
