import {Component, Input, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {ContractService} from '../../../Service/contract.service';
import {User} from "../../../Model/User";
import {UserService} from "../../../Service/user.service";
import {NotificationService} from "../../../Service/notification.service";

@Component({
  selector: 'app-debt-workflow-create-dialog',
  templateUrl: './debt-workflow-create-dialog.component.html',
  styleUrls: ['./debt-workflow-create-dialog.component.scss']
})
export class DebtWorkflowCreateDialogComponent implements OnInit {

  templates;
  step = 1;

  debtWorkflowForm: FormGroup = new FormGroup({
    refFile: new FormControl('', [Validators.required]),
    subject: new FormControl('', [Validators.required]),
    mandateDate: new FormControl('', [Validators.required]),

    creditor: new FormControl('', [Validators.required]),
    creditorAddress: new FormControl('', [Validators.required]),
    creditorZipcode: new FormControl('', [Validators.required]),
    creditorCity: new FormControl('', [Validators.required]),

    bailiffFirstname: new FormControl('', [Validators.required]),
    bailiffLastname: new FormControl('', [Validators.required]),
    bailiffEmail: new FormControl('', [Validators.required, Validators.email]),
    bailiffAddress: new FormControl('', [Validators.required]),
    bailiffZipcode: new FormControl('', [Validators.required]),
    bailiffCity: new FormControl('', [Validators.required]),

    debtorCompany: new FormControl(''),
    debtorFirstname: new FormControl('', [Validators.required]),
    debtorLastname: new FormControl('', [Validators.required]),
    debtorEmail: new FormControl('', [Validators.required, Validators.email]),
    debtorPhone: new FormControl('', [Validators.pattern(new RegExp('^((\\+)33|0|0033)[1-9](\\d{2}){4}$'))]),
    debtorAddress: new FormControl('', [Validators.required]),
    debtorZipcode: new FormControl('', [Validators.required]),
    debtorCity: new FormControl('', [Validators.required]),

    debtOrigin: new FormControl('', [Validators.required]),
    debtTotal: new FormControl('', [Validators.required]),
    debtMain: new FormControl('', [Validators.required]),
    debtInterests: new FormControl('', [Validators.required]),
    debtMethodOfPayment: new FormControl('', [Validators.required]),

    documentStep1: new FormControl(''),
    documentStep2: new FormControl(''),
    documentStep3: new FormControl(''),
  });

  translations = {
    refFile: 'Référence dossier', subject: 'Type de créance', mandateDate: 'Date mandat', creditor: 'Créditeur',
    creditorAddress: 'Adresse du créditeur', creditorZipcode: 'Code postal du créditeur', creditorCity: 'Ville du créditeur',
    bailiffFirstname: 'Prénom huissier titreur', bailiffLastname: 'Nom huissier titreur',
    bailiffEmail: 'E-mail huissier titreur', bailiffAddress: 'Adresse étude huissier titreur',
    bailiffZipcode: 'Code postal étude huissier titreur', bailiffCity: 'Ville étude huissier titreur',
    debtorFirstname: 'Prénom débiteur', debtorLastname: 'Nom débiteur', debtorEmail: 'E-mail débiteur',
    debtorPhone: 'Téléphone débiteur', debtorAddress: 'Adresse débiteur', debtorZipcode: 'Code postal débiteur',
    debtorCity: 'Ville débiteur', debtOrigin: 'Origine de la créance',
    debtTotal: 'Montant total réclamé', debtMain: 'Montant total principal',
    debtInterests: 'Montant total intérêts', debtMethodOfPayment: 'Mode de règlement'
  };

  formErrorMessage = '';

  constructor(
    public dialogRef: MatDialogRef<DebtWorkflowCreateDialogComponent>,
    private _user: UserService,
    private _contract: ContractService,
    private notification: NotificationService
  ) { }

  ngOnInit() {
    this.getCustomCMTemplates('Etape');
  }

  close() {
    this.dialogRef.close();
  }

  getCustomCMTemplates(search = '') {
    this._contract.getCustomCMTemplate(search).subscribe(
      (results) => {
        this.templates = results['results'];

        for (const prop in this.templates) {
          let template = this.templates[prop];
          if (template.title.startsWith('Etape 1')) {
            this.debtWorkflowForm.controls['documentStep1'].setValue(template.id, {onlySelf: true});
          }
          if (template.title.startsWith('Etape 2')) {
            this.debtWorkflowForm.controls['documentStep2'].setValue(template.id, {onlySelf: true});
          }
          if (template.title.startsWith('Etape 3')) {
            this.debtWorkflowForm.controls['documentStep3'].setValue(template.id, {onlySelf: true});
          }
        }
      }
    );
  }

  canSubmit() {
    this.formErrorMessage = '';
    if (this.debtWorkflowForm.status !== 'VALID') {
        this.getInvalidControls();
    }
    return this.debtWorkflowForm.status === 'VALID';
  }

  goToNextStep() {
    if (this.step < 4) {
      this.step++;
    }
  }

  goToPreviousStep() {
    if (this.step > 1) {
      this.step--;
    }
  }

  submit() {
    const contract = this.debtWorkflowForm.value;
    this._user.getUser().subscribe((user: User) => {
        this._contract.createChainContract(contract, user.apiKey).subscribe(
          (result) => {
            this.notification.send('success', 'Créance bien créée !');
            this.dialogRef.close();
          },
          (err) => {
            this.notification.send('error', err.error['hydra:description']);
          });
    });
  }

  public getInvalidControls() {
    this.formErrorMessage = 'Les champs suivants sont vides ou invalides : ';
    const invalid = [];
    const controls = this.debtWorkflowForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
        this.formErrorMessage += this.translations[name] + ', ';
      }
    }
    return invalid;
  }
}
