import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "./api.service";
import { File } from "../Model/File";

@Injectable({
  providedIn: "root",
})
export class FileService {
  constructor(private api: ApiService) {}

  getFiles(
    search: string = null,
    sortDirection: string = null
  ): Observable<File[]> {
    const options: { search?: string; sortDirection?: string } = {};
    if (search) {
      options.search = search;
    }
    if (sortDirection) {
      options.sortDirection = search;
    }
    return this.api.get("files", { search, sortDirection });
  }

  getFilesSum() {
    return this.api.get("files/sum");
  }

  getFileDate() {
    return this.api.get("files/recent");
  }

  createFile(data, key) {
    return this.api.createFile(data.attachments, key);
  }

  public deleteFile(file: File) {
    return this.api.deleteRow("file", file.id);
  }
}
