import {DataSource} from "@angular/cdk/table";
import {BehaviorSubject, Observable, of} from "rxjs";
import {CollectionViewer} from "@angular/cdk/collections";
import {catchError, finalize} from "rxjs/operators";
import {User} from "../Model/User";
import {UserAreaService} from '../Service/user-area.service';
import {UserArea} from '../Model/UserArea';

export class UserAreaDataSource implements DataSource<UserArea> {

  public userSubject = new BehaviorSubject<UserArea[]>([]);
  public lengthSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(private _userArea: UserAreaService) {}

  connect(collectionViewer: CollectionViewer): Observable<UserArea[]> {
    return this.userSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.userSubject.complete();
    this.loadingSubject.complete();
  }

  loadUserArea(filter = {}, search, sort = '',
           sortDirection = 'asc', pageIndex, pageSize) {

    this.loadingSubject.next(true);

    this._userArea.getUserArea(filter, search, sort, sortDirection,
      pageIndex, pageSize).pipe(
      catchError(() => of([])),
      finalize(() => {
          this.loadingSubject.next(false);
        }
      ))
      .subscribe(result =>  {
        this.userSubject.next(result['hydra:member']);
        this.lengthSubject.next(result['hydra:totalItems']);
      });
  }
}
