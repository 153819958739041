import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../Service/api.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(
    private _api: ApiService
  ) { }

  smtp;

  ngOnInit() {
    this.getSmtp();
  }

  getSmtp() {
    this._api.get('smtp/current').subscribe(
      (smtp) => {
        this.smtp = smtp.smtp;
      }
    );
  }

  changeSmtp() {
    this._api.get('smtp/change').subscribe(
      (result) => {
        this.getSmtp();
      }
    );
  }

}
