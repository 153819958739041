import { AfterViewInit, Component, ViewChild, OnInit } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Statistic } from "../../Model/Statistic";
import { StatisticsService } from "../../Service/statistics.service";
import { NgForm } from "@angular/forms";
import { FormBuilder } from "@angular/forms";

/**
 * @title Basic use of `<table mat-table>`
 */
@Component({
  selector: "app-statistics",
  templateUrl: "./statistics.component.html",
  styleUrls: ["./statistics.component.scss"],
})
export class StatisticsComponent implements OnInit {
  displayedColumns: string[] = [
    "company_id",
    "name",
    "nb_contracts",
    "nb_signatories",
  ];
  dataSource: MatTableDataSource<Statistic>;
  formGropDate;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private _service: StatisticsService,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.setDateForm();

    this.loadSourceAnalytic();
  }

  onSubmit(f: NgForm) {
    this.setDateForm(f.value.month, f.value.year);
    this.loadSourceAnalytic();
  }

  loadSourceAnalytic() {
    this._service
      .getAll(this.formGropDate.month, this.formGropDate.year)
      .subscribe((results) => {
        console.log(results);
        this.dataSource = new MatTableDataSource(results["data"]);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  setDateForm(month: number = null, year: number = null) {
    if (month && year) {
      this.formGropDate = {
        month,
        year,
      };
    } else {
      this.formGropDate = {
        month: new Date(Date.now()).getMonth(),
        year: new Date(Date.now()).getFullYear(),
      };
    }
  }
}
