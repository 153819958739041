import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FileService} from '../../Service/file.service';
import {FileDataSource} from '../../DataSource/FileDataSource';
import {FileContractService} from '../../Service/file-contract.service';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit, AfterViewInit {
  constructor(private file: FileService, private fileContract: FileContractService) {
  }

  dataSource: FileDataSource;
  fileSum;
  fileContactSum;
  fileDate;
  fileContractDate;

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getFilesSum();
    this.getFilesContactSum();
    this.getFileDate();
    this.getFileContractDate();
  }

  getFilesSum() {
    this.file.getFilesSum().subscribe(
      (result) => {
        this.fileSum = result;
      }
    );
  }

  getFilesContactSum() {
    this.fileContract.getFilesContactSum().subscribe(
      (result) => {
        this.fileContactSum = result;
      }
    );
  }

  getFileDate() {
    this.file.getFileDate().subscribe(
      (result) => {
        this.fileDate = result;
      }
    );
  }

  getFileContractDate() {
    this.fileContract.getFileContractDate().subscribe(
      (result) => {
        this.fileContractDate = result;
      }
    );
  }

}
