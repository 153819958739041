import {Pipe, PipeTransform} from '@angular/core';
@Pipe ({
    name : 'contractStatus'
})
export class ContractStatusPipe implements PipeTransform {
    transform(status: number): string {
        if (status === 1) {
            return 'En attente de signature';
        }
        if (status === 2) {
          return 'Terminé';
        }

        if (status === 3) {
          return 'Refusé';
        }

        if (status === 4) {
            return 'Terminé avec Refus';
        }
    }
}
