import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {UserService} from './user.service';
import {AuthService} from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    constructor(
        private _api: ApiService,
        private _user: UserService,
        private _router: Router,
        private _auth: AuthService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|Promise<boolean>|boolean {
        if (this._auth.isAuthenticated()) {
            return true;
            // return this._api.hasRoleFor(state.url);
        } else {
            this._router.navigate(['/login']);
        }
    }
}
