import {Company} from './Company';

export class User {
  '@id'?: string;
  readonly email?: string;
  readonly username?: string;
  readonly userPaidFeatures?: any[];
  company?: any;
  id?: number;
  apiKey: string;
  firstname: string;
  lastname: string;
  roles: any[];
  userAdminArea: any;
}
