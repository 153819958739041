import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FileContractService} from 'src/app/Service/file-contract.service';



@Component({
  selector: 'app-app-co2',
  templateUrl: './app-co2.component.html',
  styleUrls: ['./app-co2.component.scss']
})
export class AppCo2Component implements OnInit {

  co2;
  trees;

  constructor(private fileContract: FileContractService) { }


  ngOnInit() {
    this.fileContract.getCo2Sum().subscribe(
      (result) => {
        this.co2 = result.totalCo2 / 1000000;
        this.trees = Math.ceil(this.co2 * 1000 / 25);
      },
    );
  }

  //this.trees = this.co2


}
//customElements.define('app-app-co2', AppCo2Component);
