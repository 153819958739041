import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import {AppComponent} from './Component/app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {UserComponent} from './Component/user/user.component';
import {ContractComponent} from './Component/contract/contract.component';
import {MenuComponent} from './Component/menu/menu.component';
import {FooterComponent} from './Component/footer/footer.component';
import {
  MatAutocompleteModule,
  MatButtonModule, MatCheckboxModule, MatDatepickerModule,
  MatDialogModule, MatExpansionModule,
  MatFormFieldModule, MatIconModule,
  MatInputModule, MatListModule,
  MatMenuModule, MatNativeDateModule, MatPaginatorIntl, MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule, MatSelectModule, MatSidenavModule, MatSliderModule,
  MatSnackBarModule, MatSortModule, MatTableModule, MatTabsModule, MatTooltipModule
} from '@angular/material';
import {environment} from '../environments/environment';
import {HTTPListener, HTTPStatus} from './Service/interceptor.service';
import {AuthService} from './Service/auth.service';
import {registerLocaleData} from '@angular/common';
import {NgxCurrencyModule} from 'ngx-currency';
import {JWT_OPTIONS, JwtInterceptor, JwtModule} from '@auth0/angular-jwt';
import {CookieService} from 'ngx-cookie-service';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {RefreshTokenInterceptor} from './Service/refresh-token-interceptor';
import localeFr from '@angular/common/locales/fr';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginComponent} from './Component/login/login.component';
import {ContractStatusPipe} from './Pipe/contract-status.pipe';
import {UserDialogComponent} from './Component/user/user-dialog/user-dialog.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {UserDialogBillComponent} from './Component/user/user-dialog-bill/user-dialog-bill.component';
import {ContractDialogComponent} from './Component/contract/contract-dialog/contract-dialog.component';
import {LoginEmailComponent} from './Component/login/login-email/login-email.component';
import {CompanyComponent} from './Component/company/company.component';
import {CompanyDialogComponent} from './Component/company/company-dialog/company-dialog.component';
import {LoginEmailFormComponent} from './Component/login/login-email-form/login-email-form.component';
import {ContractCreateDialogComponent} from './Component/contract/contract-create-dialog/contract-create-dialog.component';
import {UserAreaComponent} from './Component/user-area/user-area.component';
import {UserAreaDialogComponent} from './Component/user-area/user-area-dialog/user-area-dialog.component';
import {SettingsComponent} from './Component/settings/settings.component';
import {ReportingComponent} from './Component/reporting/reporting.component';
import {SafePipe} from './Pipe/safe.pipe';
import {ContactComponent} from './Component/contact/contact.component';
import {ContactDialogComponent} from './Component/contact/contact-dialog/contact-dialog.component';
import {UserDialogPasswordComponent} from './Component/user/user-dialog-password/user-dialog-password.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CdkTableModule} from '@angular/cdk/table';
import {DragAndDropDirective} from './Directive/drag-and-drop.directive';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {NgxSpinnerModule} from 'ngx-spinner';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {TruncatePipe} from './Pipe/truncate.pipe';
import {TagInputModule} from 'ngx-chips';
import {EditorComponent} from './Component/editor/editor.component';
import {ContractCreateDialogDocumentComponent} from './Component/contract/contract-create-dialog/contract-create-dialog-document/contract-create-dialog-document.component';
import {ContractCreateDialogContactComponent} from './Component/contract/contract-create-dialog/contract-create-dialog-contact/contract-create-dialog-contact.component';
import { StatisticsComponent } from './Component/statistics/statistics.component';
import { FileComponent } from './Component/file/file.component';
import { LibraryComponent } from './Component/library/library.component';
import { FileContractComponent } from './Component/file/file-contract/file-contract.component';
import { ContractCreateDialogIndexComponent } from './Component/contract/contract-create-dialog/contract-create-dialog-index/contract-create-dialog-index.component';
import { ContractCreateDialogDocumentPrefillComponent } from './Component/contract/contract-create-dialog/contract-create-dialog-document-prefill/contract-create-dialog-document-prefill.component';
import {DebtWorkflowCreateDialogComponent} from './Component/contract/debt-workflow-create-dialog/debt-workflow-create-dialog.component';
import {InputTrimDirective} from './Directive/input-trim.directive';
import { FileCreateDialogDocumentComponent } from './Component/file/file-create-dialog-document/file-create-dialog-document.component';
import { ContractCreateDialogPrewievAddSignTagsComponent } from './Component/contract/contract-create-dialog/contract-create-dialog-prewiev-add-sign-tags/contract-create-dialog-prewiev-add-sign-tags.component';
import { AppCo2Component } from './Component/app-co2/app-co2.component';
import { OpenlogsHashComponent } from './Component/openlogs-hash/openlogs-hash.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

const RxJS_Services = [HTTPListener, HTTPStatus];

export function jwtOptionsFactory(authorizationService: AuthService) {
  return {
    tokenGetter: () => {
      return authorizationService.getAccessToken();
    },
    blacklistedRoutes: [
      `${environment.api_protocol}${environment.api_host}${environment.api_endpoint}${environment.api_version}/login`,
      `${environment.api_protocol}${environment.api_host}${environment.api_endpoint}${environment.api_version}/refresh-token`
    ],
    whitelistedDomains: [
      `${environment.api_host}`,
      `${environment.api_customCM_host}`,
    ],
  };
}

export const customCurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: '€ ',
  suffix: '',
  thousands: ' ',
  nullable: false
};

// export class MyIntl extends TimeagoIntl {
// }

registerLocaleData(localeFr);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        ContractComponent,
        UserComponent,
        MenuComponent,
        FooterComponent,
        LoginComponent,
        ContractStatusPipe,
        SafePipe,
        UserDialogComponent,
        UserDialogBillComponent,
        ContractDialogComponent,
        LoginEmailComponent,
        CompanyComponent,
        CompanyDialogComponent,
        LoginEmailFormComponent,
        ContractCreateDialogComponent,
        DebtWorkflowCreateDialogComponent,
        UserAreaComponent,
        UserAreaDialogComponent,
        SettingsComponent,
        ReportingComponent,
        ContactComponent,
        ContactDialogComponent,
        UserDialogPasswordComponent,
        DragAndDropDirective,
        InputTrimDirective,

        TruncatePipe,

        EditorComponent,

        ContractCreateDialogDocumentComponent,
        ContractCreateDialogDocumentComponent,
        ContractCreateDialogContactComponent,
        FileComponent,
        LibraryComponent,
        FileContractComponent,
        FileCreateDialogDocumentComponent,
        FileContractComponent,
        ContractCreateDialogIndexComponent,
        ContractCreateDialogDocumentPrefillComponent,
        StatisticsComponent,
        ContractCreateDialogPrewievAddSignTagsComponent,
        AppCo2Component,
        OpenlogsHashComponent,
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,

    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatSnackBarModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    MatSidenavModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    MatSelectModule,
    MatTabsModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatListModule,
    NgxIntlTelInputModule,

    SweetAlert2Module.forRoot(),
    NgxSpinnerModule,
    AutocompleteLibModule,
    TagInputModule,

    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [AuthService]
      }
    }),

    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    CdkTableModule,

    // TimeagoModule.forRoot({
    //   intl: { provide: TimeagoIntl, useClass: MyIntl},
    //   formatter: { provide: TimeagoFormatter, useClass: TimeagoCustomFormatter },
    // }),

  ],
  providers: [
    CookieService,
    JwtInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    },
    ...RxJS_Services,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPListener,
      multi: true
    },
    {provide: LOCALE_ID, useValue: "fr-FR" },
    // {provide: OWL_DATE_TIME_LOCALE, useValue: 'fr'},
    // {provide: OwlDateTimeIntl, useClass: OwlDateTimeIntlFr},
    // {provide: MatPaginatorIntl, useClass: MatPaginatorIntlFr},

    ContractStatusPipe,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    UserDialogComponent,
    UserDialogBillComponent,
    ContractDialogComponent,
    CompanyDialogComponent,
    ContractCreateDialogComponent,
    DebtWorkflowCreateDialogComponent,
    UserAreaDialogComponent,
    ContactDialogComponent,
    UserDialogPasswordComponent,
    FileCreateDialogDocumentComponent,
  ],
})
export class AppModule { }
