import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';

import { catchError, finalize, map } from 'rxjs/operators';
import {BehaviorSubject, Observable, throwError} from "rxjs";

@Injectable()
export class HTTPStatus {
  private requestInFlight$: BehaviorSubject<boolean>;
  constructor() {
    this.requestInFlight$ = new BehaviorSubject(false);
  }

  setHttpStatus(inFlight: boolean) {
    this.requestInFlight$.next(inFlight);
  }

  getHttpStatus(): Observable<boolean> {
    return this.requestInFlight$.asObservable();
  }
}

@Injectable()
export class HTTPListener implements HttpInterceptor {
  constructor(private status: HTTPStatus) {}

  intercept(
      req: HttpRequest<any>,
      next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
        map(event => {
          if (!req.url.includes('version.json')) {
            this.status.setHttpStatus(true);
          }
          return event;
        }),
        catchError(error => {
          return throwError(error);
        }),
        finalize(() => {
          setTimeout(() => {
            this.status.setHttpStatus(false);
          }, 500);
        })
    );
  }
}
