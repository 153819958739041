import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserArea} from '../../../Model/UserArea';

@Component({
  selector: 'app-user-area-dialog',
  templateUrl: './user-area-dialog.component.html',
  styleUrls: ['./user-area-dialog.component.scss']
})
export class UserAreaDialogComponent implements OnInit {

  form: FormGroup = new FormGroup({
    id: new FormControl('', []),
    name: new FormControl('', []),
  });

  constructor(
    public dialogRef: MatDialogRef<UserAreaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserArea,
  ) { }

  ngOnInit() {
    this.form.patchValue(this.data);
  }

  onSubmit() {
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }

}
