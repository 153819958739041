import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {ApiService} from "./api.service";
import {Cacheable, CacheBuster} from 'ngx-cacheable';
import {Contract} from '../Model/Contract';
import {Contact} from '../Model/Contact';
import {Company} from '../Model/Company';

const cacheBuster$ = new Subject<void>();

const CACHE_PARAM = {
  maxCacheCount: 10,
  cacheBusterObserver: cacheBuster$
};

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    private _api: ApiService,
  ) { }

  @Cacheable(CACHE_PARAM)
  getContacts(filters = {}, search = '', sort = null, sortDirection = null, page = 0, pageSize = 20): Observable<Contract[]> {
    filters['page'] = page + 1;
    filters['itemsPerPage'] = pageSize;
    if (sort) {
      filters['order[' + sort + ']'] = sortDirection;
    }
    filters['search'] = search;

    return this._api.get('contacts', filters);
  }

  @CacheBuster({cacheBusterNotifier: cacheBuster$})
  saveContact(contact: Contact) {
    let data = Object.assign({}, contact);
    if (data.id) {
      return this._api.put('contacts/' + data.id, data);
    } else {
      delete data.id;
      return this._api.postRow('contacts', data);
    }
  }

  @CacheBuster({cacheBusterNotifier: cacheBuster$})
  deleteContact(contact: Contact) {
    return this._api.deleteRow('contacts', contact.id);
  }
}
