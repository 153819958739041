import {DataSource} from '@angular/cdk/table';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {CollectionViewer} from '@angular/cdk/collections';
import {catchError, finalize} from 'rxjs/operators';
import {FileContract} from '../Model/FileContract';
import {FileContractService} from '../Service/file-contract.service';

export class FileContractDataSource implements DataSource<FileContract> {
  public filesContractSubject = new BehaviorSubject<FileContract[]>([]);
  public lengthSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(private fileContract: FileContractService) {}

  connect(collectionViewer: CollectionViewer): Observable<FileContract[]> {
    return this.filesContractSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.filesContractSubject.complete();
    this.loadingSubject.complete();
  }

  loadFileContract(perPage, page, search) {
    const idxLast = perPage * page;
    const idxFirst = idxLast - perPage;
    this.loadingSubject.next(true);

    this.fileContract
      .getFilesContract(search)
      .pipe(
        catchError(() => of([])),
        finalize(() => {
          this.loadingSubject.next(false);
        })
      )
      .subscribe((result) => {
        this.filesContractSubject.next(
          result["hydra:member"].slice(idxFirst, idxLast)
        );
        this.lengthSubject.next(result["hydra:totalItems"]);
      });
  }
}
