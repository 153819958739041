import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {UserService} from '../../../Service/user.service';

@Component({
  selector: 'app-user-dialog-password',
  templateUrl: './user-dialog-password.component.html',
  styleUrls: ['./user-dialog-password.component.scss']
})
export class UserDialogPasswordComponent implements OnInit {

  passwordForm: FormGroup = new FormGroup({
    id: new FormControl('', []),
    oldPassword: new FormControl('', []),
    newPassword: new FormControl('', []),
    newPasswordRepeat: new FormControl('', []),
  });

  constructor(
    public dialogRef: MatDialogRef<UserDialogPasswordComponent>,
    private dialog: MatDialog,
    private notification: MatSnackBar,
    private _user: UserService
  ) { }

  ngOnInit() {
    this.passwordForm.setValidators([this.passwordValidator]);
  }

  onSubmit() {
    if (this.passwordForm.valid) {
      this._user.changeMyPassword(this.passwordForm.value).subscribe(
        (result: any) => {
          if (!result.body.result) {
            this.createNotification(result.body.message);
          } else {
            this.createNotification('Mot de passe édité');
            this.dialogRef.close();
          }
        }
      );
    }
  }

  closeDialog() {
     this.dialogRef.close();
  }

  passwordValidator(group: FormGroup) {
    const newPassword = group.controls.newPassword;
    const newPasswordRepeat = group.controls.newPasswordRepeat;

    if (newPassword.value !== newPasswordRepeat.value) {
      newPasswordRepeat.setErrors({error: true});
      return {
        error: true
      };
    }
    return null;
  }

  createNotification(message) {

  }
}
