import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {User} from '../../../Model/User';
import {UserService} from '../../../Service/user.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-user-dialog-bill',
  templateUrl: './user-dialog-bill.component.html',
  styleUrls: ['./user-dialog-bill.component.scss']
})
export class UserDialogBillComponent implements OnInit {

  form: FormGroup = new FormGroup({
    from: new FormControl('', []),
    to: new FormControl('', []),
  });

  sms_logs = [];
  email_logs = [];
  sum_sms;
  sum_emails;


  constructor(
    public dialogRef: MatDialogRef<UserDialogBillComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: User,
    private _user: UserService,
    private notification: MatSnackBar,
  ) { }

  ngOnInit() {
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    const from = new Date(year, month, 1);
    const to =  new Date(year, month + 1, 0);
    this.form.controls.from.setValue(from);
    this.form.controls.to.setValue(to);
  }

  close() {
    this.dialogRef.close();
  }

  getLogs() {
    this.getSmsLogs();
    this.getEmailLogs();
  }

  getSmsLogs() {
    this.sum_sms = 0;
    this._user.getSmsLog(this.data, this.form.value.from, this.form.value.to).subscribe(
      (logs) => {
        this.sms_logs = logs['hydra:member'];
        this.countSms();
      }
    );
  }

  getEmailLogs() {
    this.sum_emails = 0;
    this._user.getEmailLog(this.data, this.form.value.from, this.form.value.to).subscribe(
      (logs) => {
        this.email_logs = logs['hydra:member'];
        this.countEmail();
      }
    );
  }

  countSms() {
    this.sms_logs.forEach((log) => {
      this.sum_sms += log.count;
    });
  }

  countEmail() {
    this.email_logs.forEach((log) => {
      this.sum_emails += log.count;
    });
  }

}
