import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../Service/auth.service';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {

  baseUrl = environment.editor_url;
  url;

  constructor(
    private _auth: AuthService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl + '?token=' + this._auth.getAccessToken() + '&refreshToken=' + this._auth.getRefreshToken());
  }
}
