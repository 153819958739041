import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-contract-create-dialog-index',
  templateUrl: './contract-create-dialog-index.component.html',
  styleUrls: ['./contract-create-dialog-index.component.scss']
})
export class ContractCreateDialogIndexComponent implements OnInit {

  @Output() documentType = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }



}
