import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";
import { Statistic } from "../Model/Statistic";

@Injectable({
  providedIn: "root",
})
export class StatisticsService {
  constructor(private _api: ApiService) {}

  getAll(month, year): Observable<Statistic[]> {
    return this._api.get("statistics", { month, year });
  }
}
