import {Component, Inject, OnInit, HostListener} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Contract} from '../../../Model/Contract';
import {ContractService} from '../../../Service/contract.service';
import {SignatoryService} from "../../../Service/signatory.service";
import {Signatory} from "../../../Model/Signatory";
import * as moment from 'moment';
import {User} from '../../../Model/User';
import {UserService} from '../../../Service/user.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-contract-dialog',
  templateUrl: './contract-dialog.component.html',
  styleUrls: ['./contract-dialog.component.scss']
})
export class ContractDialogComponent implements OnInit {

  files = [];
  date = new Date();
  identityDisplayed = false;
  viewerUrl: SafeResourceUrl;


  constructor(
    public dialogRef: MatDialogRef<ContractDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: Contract,
    private _contract: ContractService,
    private _signatory: SignatoryService,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) { }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  signatories: Signatory[] = [];
  statusSuccessList: string[] = [];
  statusUnsuccessList: string[] = [];

  ngOnInit() {
    this.statusSuccessList = ["sent", "delivered", "opened", "clicked", "clicks"];
    this.statusUnsuccessList = ["softBounce", "hardBounce", "invalidEmail", "deferred", "complaint", "unsubscribed", "blocked", "error"];

    this.getSignatories();

    this.data.signatories.map((item, idx) => {
      let contractId = item.contract.substring(item.contract.lastIndexOf('/') + 1)
      this._contract.getStatusEmail({ contractId: contractId, signatoryId: item.id }).subscribe((res: any) => {
        item.sendInBlue = {
          status: res.success ? res.message : "unknown",
          link: res.success ? "" : res.link
        };
      });
      return item;
    })

    if (this.data.status === 2 || this.data.status === 4) {
      this.getFiles();
    }
  }

  copySignature(token_url) {
    navigator.clipboard.writeText(token_url);
  }

  displayIdentity(flag) {
    this.identityDisplayed = flag;
  }

  getPdfAsBlob(pdfUrl: string): Observable<Blob> {
    return this.http.get(pdfUrl, { responseType: 'blob' });
  }

  generateBlobViewerUrl(fileUrl: string): Observable<SafeResourceUrl> {
    return this.getPdfAsBlob(fileUrl).pipe(
      map((blob: Blob) => {
        const blobUrl = URL.createObjectURL(blob);
        const fullViewerUrl = `/assets/pdf/web/viewer.html?file=${encodeURIComponent(blobUrl)}`;
        return this.sanitizer.bypassSecurityTrustResourceUrl(fullViewerUrl);
      })
    );
  }

  getFiles() {
    this._contract.getFiles(this.data.id).subscribe(
      (urls) => {
        this.files = Object.assign([], urls['hydra:member']);
      }
    );
  }

  sendReminder(signatory) {
    this._contract.sendReminder(this.data, signatory.id).subscribe(
      (result) => {
          result = result['hydra:member'][0];
          if (result) {
            this.createNotification('Rappel bien envoyé !');
          } else {
            this.createNotification('Veuillez attendre demain pour renvoyer un rappel');
          }
      }
    );
  }

  sameDay(d1, d2) {
    if (!d1 || !d2) {
      return false;
    }
    let format = 'YYYY-MM-DD HH:mm ZZ';
    let dateObj = moment(d1, format);
    if (dateObj.isValid()) {
    let date = dateObj.toDate();
        return date.getFullYear() === d2.getFullYear() &&
          date.getMonth() === d2.getMonth() &&
          date.getDate() === d2.getDate();
    }
    return false;
  }

  createNotification(message) {

  }

  base64ToBlob(base64: string, mime: string): Blob {
    const binary = atob(base64);
    const array = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
      array[i] = binary.charCodeAt(i);
    }
    return new Blob([array], { type: mime });
  }

  generateViewerUrlFromBase64(base64: string): SafeResourceUrl {
    const blob = this.base64ToBlob(base64, 'application/pdf');
    const blobUrl = URL.createObjectURL(blob);
    const fullViewerUrl = `/assets/pdf/web/viewer.html?file=${encodeURIComponent(blobUrl)}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(fullViewerUrl);
  }

  getSignatories() {
    this._user.getUser().subscribe((user: User) => {
      const feature = user.userPaidFeatures.filter((feature) => {
                    return feature.feature.name === 'CAN SEE ID DOCUMENT';
                  })[0];
                  const canSeeIdDocument = feature.enabledAt;
                  this.data.signatories.forEach((signatory: Signatory) => {
                    if (canSeeIdDocument) {
                      this._signatory.getSignatoryDocuments(signatory.id).subscribe(
                        (signatoryResult) => {
                          signatory.vitalKeysUser  = signatoryResult.vitalKeysUser;
                          signatory.handwrittenSignImg  = signatoryResult.handwrittenSignImg;
                          signatory.otpSignVideo  = signatoryResult.otpSignVideo;
                          signatory.fileSignatories = signatoryResult.fileSignatories;
                          signatory.fileSignatories.forEach((file: any) => {
                            if (file.fileType === 'pdf' && file.contentUrl) {
                              const binary = atob(file.contentUrl);
                              const array = new Uint8Array(binary.length);
                              for (let i = 0; i < binary.length; i++) {
                                array[i] = binary.charCodeAt(i);
                              }
                              const blob = new Blob([array], { type: 'application/pdf' });
                              const blobUrl = URL.createObjectURL(blob);
                              const viewerUrl = `/assets/pdf/web/viewer.html?file=${encodeURIComponent(blobUrl)}`;
                              file.safeViewerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(viewerUrl);
                            }
              });
            }
          );
        }
        this._signatory.getCode(signatory.id).subscribe(
          (code) => {
            signatory.code = code;
          }
        );
        this._signatory.getSmsID(signatory.id).subscribe(
          (smsID) => {
            signatory.smsID = smsID;
          }
        );
      });
    });

  }

  signatoriesAllSigned() {
    let result = true;
    this.data.signatories.forEach((signatory: Signatory) => {
      if (!signatory.signAt) {
        result = false;
      }
    });
    return result;
  }

  completeContract() {
    if (confirm('Attention si le contrat est généré, cela remplacera le précédent. Voulez vous continuer ?')) {
      this._contract.contractComplete(this.data).subscribe((result) => {
        this.createNotification('Contrat validé');
        setTimeout(() => {
          location.reload();
        }, 500);
      });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
