import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { ContractDataSource } from "../../DataSource/ContractDataSource";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { ContractService } from "../../Service/contract.service";
import { fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { ContractDialogComponent } from "./contract-dialog/contract-dialog.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Contract } from "../../Model/Contract";
import { ContractCreateDialogComponent } from "./contract-create-dialog/contract-create-dialog.component";
import { DebtWorkflowCreateDialogComponent } from "./debt-workflow-create-dialog/debt-workflow-create-dialog.component";
import { UserService } from "../../Service/user.service";
import { NotificationService } from "../../Service/notification.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-contract",
  templateUrl: "./contract.component.html",
  styleUrls: ["./contract.component.scss"],
})
export class ContractComponent implements OnInit, AfterViewInit {
  dataSource: ContractDataSource;
  displayedColumns: string[] = [
    "contractId",
    "creationDate",
    "sender",
    "subject",
    "signatories",
    "signDate",
    "status",
    "action",
  ];
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  length: number = 0;

  dialogRef: MatDialogRef<ContractDialogComponent>;
  dialogCreateRef: MatDialogRef<ContractCreateDialogComponent>;
  dialogCreateDebtRef: MatDialogRef<DebtWorkflowCreateDialogComponent>;

  contractSum = {
    total: 0,
    pending: 0,
    complete: 0,
    contract_draft: 0,
    canceled: 0,
  };

  filter_pending = false;
  filter_complete = false;
  filter_declined = false;
  filter_canceled = false;
  filter_draft = false;
  filter_all = true;

  user;
  canCreate = true;
  canCreateDebtWorkflow = false;

  perPage = 50;
  page = 1;
  maxPage;

  @ViewChild("search_input", { static: true }) search_input;

  constructor(
    private _contract: ContractService,
    public dialog: MatDialog,
    private _user: UserService,
    private notification: NotificationService
  ) {}

  ngOnInit() {
    this.dataSource = new ContractDataSource(this._contract);
    this.getSum();
    this._user.getUser().subscribe((user) => {
      this.user = user;
      if (this.user.userAdminArea) {
        this.canCreate = true;
      }
      const feature = this.user.userPaidFeatures.filter((feature) => {
        return feature.feature.name === "REGISTER";
      })[0];
      if (feature.enabledAt) {
        this.canCreate = true;
      }

      const featureDebtWorkflow = this.user.userPaidFeatures.filter(
        (feature) => {
          return feature.feature.name === "ENABLE DEBT RECOVERING WORKFLOW";
        }
      )[0];
      if (featureDebtWorkflow.enabledAt) {
        this.canCreateDebtWorkflow = true;
      }
    });
  }

  ngAfterViewInit() {
    this.loadContractPage();

    fromEvent(this.search_input.nativeElement, "keyup")
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          // this.paginator.pageIndex = 0;
          this.loadContractPage();
        })
      )
      .subscribe();

    // reset the paginator after sorting
    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // on sort or paginate events, load a new page
    // merge(this.sort.sortChange, this.paginator.page)
    //   .pipe(
    //     tap(() => this.loadContractPage())
    //   )
    //   .subscribe();
  }

  loadContractPage() {
    let filter = {};

    if (this.filter_pending) {
      filter["status"] = 1;
    }
    if (this.filter_complete) {
      filter["status"] = [2, 3, 4];
    }
    if (this.filter_declined) {
    }
    if (this.filter_canceled) {
      filter["status"] = 5;
    }

    if (this.filter_draft) {
      filter["status"] = 100;
      this.dataSource.loadDraftContracts();
      this.dataSource.lengthSubject.asObservable().subscribe((length) => {
        this.length = length;
        this.maxPage = Math.ceil(length / this.perPage);
      });
    } else {
      this.dataSource.loadContract(
        filter,
        this.search_input.nativeElement.value,
        "",
        "asc",
        this.page - 1,
        this.perPage
        // this.sort.active,
        // this.sort.direction,
        // this.paginator.pageIndex,
        // this.paginator.pageSize
      );
      this.dataSource.lengthSubject.asObservable().subscribe((length) => {
        this.length = length;
        this.maxPage = Math.ceil(length / this.perPage);
        this.getSum();
      });
    }
  }

  openDialog(contract: Contract) {
    this.loadContractPage();
    this.dialogRef = this.dialog.open(ContractDialogComponent, {
      disableClose: false,
      data: contract,
      panelClass: "modal-style",
    });
  }

  getSum() {
    this._contract.getContractsSum().subscribe((result) => {
      this.contractSum = { ...this.contractSum, ...result };
    });
  }

  toogleFilter(filter) {
    if (filter === "pending") {
      this.filter_complete = false;
      this.filter_declined = false;
      this.filter_all = false;
      this.filter_canceled = false;
      this.filter_draft = false;
      this.filter_pending = !this.filter_pending;
    }
    if (filter === "complete") {
      this.filter_pending = false;
      this.filter_declined = false;
      this.filter_all = false;
      this.filter_canceled = false;
      this.filter_draft = false;
      this.filter_complete = !this.filter_complete;
    }
    if (filter === "declined") {
      this.filter_complete = false;
      this.filter_pending = false;
      this.filter_all = false;
      this.filter_canceled = false;
      this.filter_draft = false;
      this.filter_declined = !this.filter_declined;
    }
    if (filter === "canceled") {
      this.filter_complete = false;
      this.filter_pending = false;
      this.filter_all = false;
      this.filter_declined = false;
      this.filter_draft = false;
      this.filter_canceled = !this.filter_canceled;
    }
    if (filter === "contract_draft") {
      this.filter_complete = false;
      this.filter_pending = false;
      this.filter_all = false;
      this.filter_declined = false;
      this.filter_canceled = false;
      this.filter_draft = true;
    }
    if (filter === "all") {
      this.filter_complete = false;
      this.filter_pending = false;
      this.filter_declined = false;
      this.filter_canceled = false;
      this.filter_draft = false;
      this.filter_all = true;
    }

    this.resetSearch();
    this.getSum();
  }

  resetSearch() {
    this.page = 1;
    this.loadContractPage();
  }

  createContract() {
    this.dialogCreateRef = this.dialog.open(ContractCreateDialogComponent, {
      disableClose: false,
    });
    this.loadContractPage();
  }

  createDebtWorkflow() {
    this.dialogCreateDebtRef = this.dialog.open(
      DebtWorkflowCreateDialogComponent,
      {
        disableClose: false,
      }
    );
  }

  sendReminder(contract, signatory) {
    this._contract.sendReminder(contract, signatory.id).subscribe((result) => {
      this.notification.send("success", "Rappel bien envoyé !");
    });
  }

  resendContractDraft(contractDraft) {
    this._contract.resendContractDraft(contractDraft.id).subscribe((result) => {
      console.log(result);
      this.notification.send("success", "Rappel bien envoyé !");
    });
  }

  resendContract(contract) {
    this._contract.resendContract(contract).subscribe((result) => {
      this.notification.send(
        "success",
        "Documents envoyé par email aux signataires"
      );
    });
  }

  cancelContract(contract) {
    const onSuccess = (res) => {
      var { value, isConfirmed } = res;
      value = Boolean(value);
      if (value && isConfirmed) {
        this._contract.cancelContract(contract).subscribe((result) => {
          this.getSum();
          this.loadContractPage();
          this.notification.send("success", "Contrat annulé");
        });
      }
    };
    Swal.fire({
      title: "Êtes-vous sûr ?",
      icon: "warning",
      input: "checkbox",
      inputPlaceholder: `Cochez la case si vous souhaitez annuler<br> le contrat <b>${contract.subject}</b>`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Valider",
      cancelButtonText: "Annuler",
      inputValidator: (result) => {
        return !result && "Cochez la case si vous continuer";
      },
    }).then(onSuccess);
  }

  countSignatories(contract) {
    const total = contract.signatories.length;
    let signed = 0;

    contract.signatories.forEach((signatory) => {
      if (signatory.signAt || signatory.refusedAt) {
        signed++;
      }
    });

    return signed + "/" + total;
  }

  changePage(page) {
    this.page = page;
    this.loadContractPage();
  }

  changePerPage(event) {
    this.perPage = event;
    this.resetSearch();
  }

  getSignatoryCount(signatories) {
    let count = 0;
    signatories.forEach((signatory) => {
      if (signatory.signAt || signatory.refusedAt) {
        count++;
      }
    });
    return count;
  }
}
