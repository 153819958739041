import {DataSource} from "@angular/cdk/table";
import {BehaviorSubject, Observable, of} from "rxjs";
import {CollectionViewer} from "@angular/cdk/collections";
import {catchError, finalize} from "rxjs/operators";
import {ContactService} from '../Service/contact.service';
import {Contact} from '../Model/Contact';

export class ContactDataSource implements DataSource<Contact> {

    public contactsSubject = new BehaviorSubject<Contact[]>([]);
    public lengthSubject = new BehaviorSubject<number>(0);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private _contact: ContactService) {}

    connect(collectionViewer: CollectionViewer): Observable<Contact[]> {
        return this.contactsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.contactsSubject.complete();
        this.loadingSubject.complete();
    }

    loadContact(filter = {}, search, sort = '',
                sortDirection = 'asc', pageIndex, pageSize) {

        this.loadingSubject.next(true);

        this._contact.getContacts(filter, search, sort, sortDirection,
            pageIndex, pageSize).pipe(
            catchError(() => of([])),
            finalize(() => {
                    this.loadingSubject.next(false);
                }
            ))
            .subscribe(result =>  {
                this.contactsSubject.next(result['hydra:member']);
                this.lengthSubject.next(result['hydra:totalItems']);

            });
    }
}
