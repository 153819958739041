import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../Service/api.service';
import {UserService} from '../../Service/user.service';
import {Router} from '@angular/router';
import {AuthService} from '../../Service/auth.service';
import {FormBuilder, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material';
import {NotificationService} from '../../Service/notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm;
  autoLoginForm;
  themeLogo = 'deepblock.png';

  constructor(
      private _api: ApiService,
      private _user: UserService,
      private _router: Router,
      private _auth: AuthService,
      private fb: FormBuilder,
      public snackBar: MatSnackBar,
      private notification: NotificationService,
  ) { }

  ngOnInit() {
    if (this._auth.isAuthenticated()) {
      this._router.navigate(['']);
    }

    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(1)]],
      password: ['', [Validators.required, Validators.minLength(1)]],
    });

    if (location.hostname !== 'admin.vitalsign.eu' && location.hostname !== 'admin-dev.vitalsign.fr') {
      this.themeLogo = 'deepblock.png';
    }
  }

  public onSubmit(): void {
    this._auth.login(this.loginForm.controls.username.value.toLowerCase(), this.loginForm.controls.password.value).subscribe(
        (result) => {
          this._user.getUser(true).subscribe(
              (user) => {
                  this._router.navigate(['']);
              }
          );
        },
        (error) => {
          this.notification.send('error', 'Mauvais Identifiants !');
        }
    );
  }

  onAutoLoginSubmit() {
    this._auth.askForLoginToken(this.autoLoginForm.value.username).subscribe(
      (result) => {
          if (result.result) {
            this.createNotification('Email envoyé');
          }
      }
    );
  }

  createNotification(message) {

  }
}
