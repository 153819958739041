import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import {FileContractDataSource} from '../../../DataSource/FileContractDataSource';
import {FileContractService} from '../../../Service/file-contract.service';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { fromEvent, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";

@Component({
  selector: "app-file-contract",
  templateUrl: "./file-contract.component.html",
  styleUrls: ["./file-contract.component.scss"],
})
export class FileContractComponent implements OnInit, AfterViewInit {
  dataSource: FileContractDataSource;

  @ViewChild("search_input", { static: true }) search_input;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  length: number = 0;

  perPage = 50;
  page = 1;
  maxPage;

  constructor(private fileContract: FileContractService) {}

  ngOnInit() {
    this.dataSource = new FileContractDataSource(this.fileContract);
    this.maxPage = this.dataSource.lengthSubject.value / this.perPage;
  }

  ngAfterViewInit() {
    this.loadFileContractPage();
        fromEvent(this.search_input.nativeElement, "keyup")
          .pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(() => {
              // this.paginator.pageIndex = 0;
              this.loadFileContractPage();
            })
          )
          .subscribe();

  }

  loadFileContractPage() {
    this.dataSource.loadFileContract(
      this.perPage,
      this.page,
      this.search_input.nativeElement.value
    );
    this.dataSource.lengthSubject.asObservable().subscribe((length) => {
      this.length = length;
      this.maxPage = Math.ceil(length / this.perPage);
    });
  }

  copyPath(val: string) {
    const selBox = document.createElement("textarea");
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  changePage(page) {
    this.page = page;
    this.loadFileContractPage();
  }

  changePerPage(event) {
    this.perPage = event;
    this.loadFileContractPage();
  }
}
