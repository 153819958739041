import {Component, OnInit} from '@angular/core';
import {CompanyService} from '../../Service/company.service';
import * as moment from 'moment';

@Component({
  selector: 'app-company',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent implements OnInit {

  companies = [];
  displayedColumns: string[] = ['username', 'contractSum', 'smsSum'];
  dateFrom: Date;
  dateTo: Date;

  currentYear = new Date().getFullYear();
  year = new Date().getFullYear();
  months;
  kpis = ['users', 'contract', 'email', 'sms', 'signatories'];
  kpisLabel = ['Utilisateurs actif', 'Contrat', 'Courriel', 'SMS', 'Signataire'];

  yearLimit = 2020;

  length = 0;
  perPage = 10;
  page = 1;
  maxPage;

  constructor(
    private _company: CompanyService,
  ) { }

  ngOnInit() {
    this.refresh();

    moment.locale('fr');
    this.months = moment.months();
  }

  refresh() {
    let filters = {
      page: this.page,
      itemsPerPage: this.perPage,
    };
    if (this.dateFrom && this.dateTo) {
      filters['reportDateFrom'] = moment(this.dateFrom).format('DD-MM-YYYY');
      filters['reportDateTo'] = moment(this.dateTo).format('DD-MM-YYYY');
    }
    this._company.getCompanyForReport(this.year, filters).subscribe(
      (result) => {
        this.length = result['hydra:member'][1];
        this.maxPage = Math.ceil(this.length / this.perPage);
        this.companies = result['hydra:member'][0];
      }
    );
  }

  changeYear(change) {
    this.year += change;
    if (this.year < this.yearLimit) {
      this.year = this.yearLimit;
      return;
    }
    this.refresh();
  }

  changePage(page) {
    this.page = page;
    this.refresh();
  }

  changePerPage(event) {
    this.perPage = event;
    this.refresh();
  }
}
