import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBarRef } from "@angular/material/snack-bar";
import { User } from "../../Model/User";
import { ContactService } from "../../Service/contact.service";
import { ContactDataSource } from "../../DataSource/ContactDataSource";
import { ContactDialogComponent } from "./contact-dialog/contact-dialog.component";
import { Contact } from "../../Model/Contact";
import { NotificationService } from "../../Service/notification.service";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { CompanyService } from "../../Service/company.service";
import { Company } from "../../Model/Company";
import { FormArray, FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit, AfterViewInit {
  dataSource: ContactDataSource;
  displayedColumns: string[] = [
    "email",
    "name",
    "phone",
    "company",
    "type",
    "action",
  ];
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  length: number = 0;

  dialogRef: MatDialogRef<ContactDialogComponent>;

  perPage = 10;
  page = 1;
  maxPage;

  constructor(
    private _contact: ContactService,
    public dialog: MatDialog,
    private notification: NotificationService,
    private _company: CompanyService
  ) {}

  ngOnInit() {
    this.dataSource = new ContactDataSource(this._contact);
    this.dataSource.lengthSubject.asObservable().subscribe((length) => {
      this.length = length;
      this.maxPage = Math.ceil(length / this.perPage);
    });
  }

  ngAfterViewInit() {
    this.loadContactPage();

    // reset the paginator after sorting
    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // on sort or paginate events, load a new page
    // merge(this.sort.sortChange, this.paginator.page)
    //   .pipe(
    //     tap(() => this.loadContactPage())
    //   )
    //   .subscribe();
  }

  loadContactPage() {
    const filter = {};

    this.dataSource.loadContact(
      filter,
      // this.search_input.nativeElement.value,
      "",
      "",
      "asc",
      this.page - 1,
      this.perPage
    );
  }

  openContactDialog(user: User = null) {
    this.dialogRef = this.dialog.open(ContactDialogComponent, {
      disableClose: true,
      data: user,
      panelClass: "modal-style",
    });

    this.dialogRef.afterClosed().subscribe((company) => {
      if (company) {
        this._contact.saveContact(company).subscribe(
          (result) => {
            this.loadContactPage();
            this.notification.send("success", "Contact éditée");
          },
          (err) => {
            this.notification.send("error", err.error["hydra:description"]);
          }
        );
      }
    });
  }

  deleteContact(contact: Contact = null) {
    Swal.fire({
      title: "Êtes-vous sûr ?",
      text:
        "Voulez-vous supprimer le contact " +
        contact.firstname +
        " " +
        contact.lastname +
        " ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        this._contact.deleteContact(contact).subscribe(() => {
          this.loadContactPage();
          this.notification.send("success", "Contact supprimé");
        });
      }
    });
  }

  changePage(page) {
    this.page = page;
    this.loadContactPage();
  }

  changePerPage(event) {
    this.perPage = event;
    this.page = 1;
    this.loadContactPage();
  }

  loadFile(data) {
    let workBook = XLSX.read(data, { type: "binary" });
    return workBook.SheetNames.reduce(
      (initial, name) => {
        const sheet = workBook.Sheets[name];
        initial.data = { ...initial.data, ...XLSX.utils.sheet_to_json(sheet) };
        return initial;
      },
      {
        data: [],
      }
    );
  }
  parseExel(result) {
    const datas = [];
    Object.values(result.data).map(function (ele, index) {
      datas.push(ele);
    });
    return datas;
  }
  validateFildsExel(contact, errors = [], idx = null) {
    const message = (message) => new TypeError(`${message} - LINE ${idx}`);
    /**
     * VALIDATE GARDER
     */
    if (!contact["civilité"] && !contact["civilite"]) {
      errors.push(message("Indiquez le nom civil"));
    }
    /**
     * VALIDATE COMPANY
     */
    if (contact.company) {
      this._company.getCompanies({}, contact.company).subscribe((res) => {
        if (res["hydra:totalItems"]) {
          contact.company = res["hydra:member"][0];
        } else {
          var companyForm: FormGroup = new FormGroup({
            id: new FormControl("", []),
            name: new FormControl(contact.company, []),
            terms: new FormControl("", []),
            logo: new FormControl("", []),
            tokenOpenLog: new FormControl("", []),
          });
          this._company.saveCompany(companyForm.value).subscribe(
            (result) => {
              contact.company = result;
            },
            (err) => {
              errors.push(err.error["hydra:description"]);
            }
          );
        }
      });
    }
    /**
     * VALIDATE EMAIL
     */
    if (
      !contact.email ||
      contact.email == " " ||
      !contact.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/)
    ) {
      errors.push(message("Adresse e-mail invalide"));
    }
    /**
     * VALIDATE FIRSTNAME
     */
    if (!contact.firstname || contact.firstname == " ") {
      errors.push(message("Prénom non valide"));
    }
    /**
     * VALIDATE LASTNAME
     */
    if (!contact.lastname || contact.lastname == " ") {
      errors.push(message("Nom de famille invalide"));
    }
    /**
     * VALIDATE TYPE
     */
    if (
      !contact.type ||
      contact.type == " " ||
      (contact.type.toLowerCase() !== "external" &&
        contact.type.toLowerCase() !== "internal")
    ) {
      errors.push(
        message("Type de contact non valide ('External' ou 'Internal')")
      );
    }
    /**
     * VALIDATE PHONE
     */
    if (contact.phone) {
       let phoneNormalisePattern = /[-.() ]/gm;
       let phonePattern = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/gim;

      if (contact.phone.match(phoneNormalisePattern)) {
        contact.phone = contact.phone.split(phoneNormalisePattern).join("");
      }
      if (!contact.phone.match(phonePattern)) {
        errors.push(message("Numéro de téléphone invalide"));
      }
    }
    /**
     * VALIDATE ZIPCODE
     */
    if (
      contact.zipcode &&
      !contact.zipcode.match(/^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/)
    ) {
      errors.push(message("Code postal invalide"));
    }

    return errors;
  }
  onFileChange(event) {
    const reader = new FileReader();
    const file = event.target.files[0];
    var error: TypeError[] = [];
    reader.onload = () => {
      this.parseExel(this.loadFile(reader.result)).map(
        (contact: any, idx: number, datas) => {
          error = this.validateFildsExel(contact, error, idx + 1);
          if (error.length) {
            return this.notification.send("error", error[0]);
          }
          try {
            contact = {
              email: contact.email,
              firstname: contact.firstname,
              lastname: contact.lastname,
              phoneMobile: contact.phone ? contact.phone.toString() : null,
              gender: contact["civilité"] || contact["civilite"] || null,
              type: contact.type.toLowerCase() || null,
              company: contact.company || null,

              address1: contact.address1 || null,
              address2: contact.address2 || null,
              zipcode: contact.zipcode ? contact.zipcode.toString() : null,
              city: contact.city || null,
              country: contact.country || null,
            };

            this._contact.saveContact(contact).subscribe(
              (result) => {
                this.loadContactPage();
                if (idx === datas.length - 1) {
                  this.notification.send(
                    "success",
                    `${datas.length} contacts ont été enregistrés`
                  );
                } else {
                  this.notification.send("success", "Contact éditée");
                }
              },
              (err) => {
                this.notification.send("error", err.error["hydra:description"]);
              }
            );
          } catch (err) {
            error.push(err);
            return this.notification.send("error", error[0]);
          }
        }
      );
    };
    reader.readAsBinaryString(file);
  }
}
