export class Contact {
  "@id"?: string;
  id?: number;
  email: string;
  firstname: string;
  lastname: string;
  phoneMobile: string;
  data: any;
  gender;
  signPosition: number;
  address1?: string;
  address2?: string;
  zipcode?: string;
  city?: string;
  country?: string;
}
