// @ts-ignore
import { Component, OnInit } from '@angular/core';
import {FileContractService} from '../../Service/file-contract.service';
import {ApiService} from '../../Service/api.service';
import {HttpClient} from '@angular/common/http';
import {OpenLogHashes} from "../../Model/OpenLogHashes";

@Component({
  selector: 'app-openlogs-hash',
  templateUrl: './openlogs-hash.component.html',
  styleUrls: ['./openlogs-hash.component.scss']
})
export class OpenlogsHashComponent implements OnInit {
  displayedColumns: string[] = [
    'hash',
    'createdAt',
    'action',
  ];

  openLogHashes = [];
  TMPOpenLogHashes = [];
  searchQuery = '';

  constructor(
    private fileContract: FileContractService,
    private api: ApiService,
    ) { }


  ngOnInit() {
    this.getOpenLogsHashes();
  }

  getOpenLogsHashes() {
    this.api.getHashAPI().subscribe(
      (result: OpenLogHashes) => {
        this.openLogHashes = result.hashes;
        this.TMPOpenLogHashes = result.hashes;
        // this.openLogHashes = [];
        // this.TMPOpenLogHashes = [];
        return this.openLogHashes;
      }
    );
  }

  search() {
    if (!this.searchQuery) {
      this.TMPOpenLogHashes = this.openLogHashes;
      return;
    }
    this.TMPOpenLogHashes = this.openLogHashes.filter(hash =>
      hash.hash.includes(this.searchQuery)
    );
  }

  deleteHash(id: string) {

    if (confirm('Êtes-vous sûr de vouloir supprimer ce hash ?') === true) {
      this.api.deleteHashAPI(id).subscribe();
      this.TMPOpenLogHashes = this.TMPOpenLogHashes.filter(res => res._id !== id);
      this.openLogHashes = this.openLogHashes.filter(res => res._id !== id);
    }
  }

}
