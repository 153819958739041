import { DataSource } from "@angular/cdk/table";
import { BehaviorSubject, Observable, of } from "rxjs";
import { CollectionViewer } from "@angular/cdk/collections";
import { catchError, finalize } from "rxjs/operators";
import { ContractService } from "../Service/contract.service";
import { Contract } from "../Model/Contract";

export class ContractDataSource implements DataSource<Contract> {
  public contractsSubject = new BehaviorSubject<Contract[]>([]);
  public lengthSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(private _contract: ContractService) {}

  connect(collectionViewer: CollectionViewer): Observable<Contract[]> {
    return this.contractsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.contractsSubject.complete();
    this.loadingSubject.complete();
  }

  loadContract(
    filter = {},
    search,
    sort = "",
    sortDirection = "asc",
    pageIndex,
    pageSize
  ) {
    this.loadingSubject.next(true);

    this._contract
      .getContracts(filter, search, sort, sortDirection, pageIndex, pageSize)
      .pipe(
        catchError(() => of([])),
        finalize(() => {
          this.loadingSubject.next(false);
        })
      )
      .subscribe((result) => {
        this.contractsSubject.next(result["hydra:member"]);
        this.lengthSubject.next(result["hydra:totalItems"]);
      });
  }

  loadDraftContracts() {
    this.loadingSubject.next(true);

    this._contract
      .getDraftContracts()
      .pipe(
        catchError(() => of([])),
        finalize(() => {
          this.loadingSubject.next(false);
        })
      )
      .subscribe((result) => {
        this.contractsSubject.next(result);
        this.lengthSubject.next(result.length);
      });
  }
}
