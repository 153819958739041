import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Contact } from "../../../Model/Contact";
import { ContactService } from "../../../Service/contact.service";
import { FormControl, FormGroup } from "@angular/forms";
import { NotificationService } from "../../../Service/notification.service";
@Component({
  selector: "app-contact-dialog",
  templateUrl: "./contact-dialog.component.html",
  styleUrls: ["./contact-dialog.component.scss"],
})
export class ContactDialogComponent implements OnInit {
  contact: Contact;

  contactForm: FormGroup = new FormGroup({
    id: new FormControl("", []),
    email: new FormControl("", []),
    firstname: new FormControl("", []),
    lastname: new FormControl("", []),
    phoneMobile: new FormControl("", []),
    company: new FormControl("", []),
    type: new FormControl("external", []),
    gender: new FormControl("Monsieur", []),

    address1: new FormControl("", []),
    address2: new FormControl("", []),
    zipcode: new FormControl("", []),
    city: new FormControl("", []),
    country: new FormControl("", []),
  });

  constructor(
    public dialogRef: MatDialogRef<ContactDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: Contact,
    private _contact: ContactService,
    private notification: MatSnackBar,
    private alert: NotificationService
  ) {}

  ngOnInit() {
    if (this.data && !this.contact) {
      this.contact = this.data;
      if (this.contact.data && !Array.isArray(this.contact.data)) {
        this.contact.data = JSON.parse(this.contact.data);
      }

      this.contactForm.patchValue(this.contact);
    }
  }

  close() {
    this.dialogRef.close();
  }

  checkInitContact(contact) {
    if (
      this.validate(contact, "firstname") &&
      this.validate(contact, "lastname") &&
      this.validate(contact, "email")
    ) {
      return true;
    }
    return false;
  }

  validate(contact, fild) {
    if (
      fild == "email" &&
      !Boolean(contact[fild]) &&
      !contact.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/)
    ) {
      return false;
    }
    if (fild == "phoneMobile" && Boolean(contact[fild])) {
      let phoneNormalisePattern = /[-.() ]/gm;
      let phonePattern = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/gim;
      if (!contact[fild].match(phonePattern)) {
        return false;
      }
      if (contact[fild].match(phoneNormalisePattern)) {
        console.log();
        this.contactForm.controls.phoneMobile.setValue(
          contact[fild].split(phoneNormalisePattern).join("")
        );
      }
    }
    return Boolean(contact[fild]);
  }

  onSubmit() {
    if (!this.validate(this.contactForm.value, "firstname")) {
      this.alert.send("warning", "Prénom non valide");
      return 0;
    }
    if (!this.validate(this.contactForm.value, "lastname")) {
      this.alert.send("warning", "Nom de famille invalide");
      return 0;
    }
    if (!this.validate(this.contactForm.value, "email")) {
      this.alert.send("warning", "Adresse électronique non valide");
      return 0;
    }

    if (
      this.contactForm.value.phoneMobile &&
      !this.validate(this.contactForm.value, "phoneMobile")
    ) {
      this.alert.send("warning", "Numéro de téléphone non valide");
      return 0;
    }

    this.dialogRef.close(this.contactForm.value);
  }
}
