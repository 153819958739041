import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";
import { FileContract } from "../Model/FileContract";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class FileContractService {
  constructor(private api: ApiService, private http: HttpClient) {}

  getFilesContract(
    search: string = null,
    sortDirection: string = null
  ): Observable<FileContract[]> {
    const options: { search?: string; sortDirection?: string } = {};
    if (search) {
      options.search = search;
    }
    if (sortDirection) {
      options.sortDirection = search;
    }
    return this.api.get("get_contracts", { search, sortDirection });
  }

  getFilesContactSum() {
    return this.api.get("files_contract/sum");
  }

  getFileContractDate() {
    return this.api.get("files_contract/recent");
  }

  getCo2Sum() {
    return this.api.get("co2");
  }
}
