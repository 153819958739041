import {EventEmitter, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, ReplaySubject } from 'rxjs';
import {LoginResponse} from '../Model/LoginResponse';
import {CookieService} from 'ngx-cookie-service';
import {Router} from "@angular/router";
import {ApiService} from './api.service';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor (
      private httpClient: HttpClient,
      private cookieService: CookieService,
      private _router: Router,
      private _api: ApiService,
      private notification: MatSnackBar,
  ) {
  }

  onLogout = new EventEmitter();
  onLogin = new EventEmitter();

  loginCheckUrl = `${environment.api_protocol}${environment.api_host}${environment.api_endpoint}${environment.api_version}/login`;
  loginTokenCheckUrl = `${environment.api_protocol}${environment.api_host}${environment.api_endpoint}${environment.api_version}/login-token`;
  refreshTokenUrl = `${environment.api_protocol}${environment.api_host}${environment.api_endpoint}${environment.api_version}/refresh-token`;

  login (username: string, password: string): Observable<LoginResponse> {
    // const body = new HttpParams()
    //     .set('username', username)
    //     .set('password', password);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const postObservable = this.httpClient.post<LoginResponse>(this.loginCheckUrl, {username: username, password: password}, { headers });

    const subject = new ReplaySubject<LoginResponse>(1);
    subject.subscribe((r: LoginResponse) => {
      this.setAccessToken(r.token);
      this.setRefreshToken(r.refresh_token);
      this.onLogin.emit();
    }, (err) => {
      this.handleAuthenticationError(err);
    });

    postObservable.subscribe(subject);
    return subject;
  }

  askForLoginToken(email) {
    return this._api.get('login-token/' + email);
  }


  loginToken(token: string): Observable<LoginResponse> {

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const postObservable = this.httpClient.post<LoginResponse>(this.loginTokenCheckUrl, {token: token}, { headers });

    const subject = new ReplaySubject<LoginResponse>(1);
    subject.subscribe((r: LoginResponse) => {
      this.setAccessToken(r.token);
      this.setRefreshToken(r.refresh_token);
      this.onLogin.emit();
    }, (err) => {

      this.handleAuthenticationError(err);
    });

    postObservable.subscribe(subject);
    return subject;
  }

  refresh (): Observable<LoginResponse> {
    const body = new HttpParams().set('refresh_token', this.getRefreshToken());

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    const refreshObservable = this.httpClient.post<LoginResponse>(this.refreshTokenUrl, body.toString(), { headers });

    const refreshSubject = new ReplaySubject<LoginResponse>(1);
    refreshSubject.subscribe((r: LoginResponse) => {
      this.setAccessToken(r.token);
      this.setRefreshToken(r.refresh_token);
    }, (err) => {
      this.handleAuthenticationError(err);
    });

    refreshObservable.subscribe(refreshSubject);
    return refreshSubject;
  }

  logout () {
    this.setAccessToken(null);
    this.setRefreshToken(null);
    this.onLogout.emit();
    this._router.navigate(['login']);
  }

  isAuthenticated (): boolean {
    return !!this.getAccessToken();
  }

  private handleAuthenticationError (err: any) {
    // TODO: Only for authentication error codes
    this.setAccessToken(null);
    this.setRefreshToken(null);
    console.log(err.error);
    this.createNotification(err.error.message);
    this._router.navigate(['/']);
  }

  private setAccessToken (accessToken: string) {
    if (!accessToken) {
      this.cookieService.delete('token-back');
    } else {
      this.cookieService.set('token-back', accessToken, null, '/', null, false, 'Lax');
    }
  }

  private setRefreshToken (refreshToken: string) {
    if (!refreshToken) {
      this.cookieService.delete('refresh_token-back');
    } else {
      this.cookieService.set('refresh_token-back', refreshToken, null, '/', null, false, 'Lax');
    }
  }

  getAccessToken() {
    return this.cookieService.get('token-back');
  }

  getRefreshToken() {
    return this.cookieService.get('refresh_token-back');
  }

  createNotification(message) {

  }
}
