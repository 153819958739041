import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ApiService} from '../../../Service/api.service';
import {UserService} from '../../../Service/user.service';
import {Router} from '@angular/router';
import {AuthService} from '../../../Service/auth.service';
import {MatSnackBar} from '@angular/material';
import {MatSnackBarRef} from '@angular/material/snack-bar';

@Component({
  selector: 'app-login-email-form',
  templateUrl: './login-email-form.component.html',
  styleUrls: ['./login-email-form.component.scss']
})
export class LoginEmailFormComponent implements OnInit {

  autoLoginForm;
  themeLogo = 'deepblock.png';

  constructor(
    private _api: ApiService,
    private _user: UserService,
    private _router: Router,
    private _auth: AuthService,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private notification: MatSnackBar,
  ) { }

  ngOnInit() {
    if (this._auth.isAuthenticated()) {
      this._router.navigate(['']);
    }

    this.autoLoginForm = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(1)]],
    });

    if (location.hostname !== 'admin.vitalsign.eu' && location.hostname !== 'admin-dev.vitalsign.fr') {
      this.themeLogo = 'deepblock.png';
    }
  }

  onAutoLoginSubmit() {
    this._auth.askForLoginToken(this.autoLoginForm.value.username).subscribe(
      (result) => {
        if (result.result) {
          this.createNotification('Email envoyé');
        }
      }
    );
  }

  createNotification(message) {

  }

}
