import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {User} from "../../../Model/User";
import {FormControl, FormGroup} from "@angular/forms";
import {UserService} from "../../../Service/user.service";
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {CompanyService} from '../../../Service/company.service';
import {Company} from '../../../Model/Company';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {

  user: User = new User();
  companies: Company[] = [];
  companies_filtered: Observable<Company[]>;

  userForm: FormGroup = new FormGroup({
    id: new FormControl('', []),
    username: new FormControl('', []),
    firstname: new FormControl('', []),
    lastname: new FormControl('', []),
    plainPassword: new FormControl('', []),
    company: new FormControl('', []),
    enabled: new FormControl(true, []),
    featureCallBackUrl: new FormControl('', []),
    isAdmin: new FormControl(false, []),
  });

  isSuperAdmin = false;

  constructor(
    public dialogRef: MatDialogRef<UserDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: User,
    private _user: UserService,
    private notification: MatSnackBar,
    private _company: CompanyService,
  ) { }

  ngOnInit() {
    this._user.getUser().subscribe((user) => {
        this.isSuperAdmin = user.roles.includes('ROLE_SUPER_ADMIN');
    });
    if (this.data) {
      this.user = this.data;
      this.userForm.patchValue(this.user);
      if (this.user['roles'].includes('ROLE_ADMIN') || this.user['roles'].includes('ROLE_SUPER_ADMIN')) {
        this.userForm.controls.isAdmin.setValue(true);
      }
      const featureCallBack = this.getFeature('CALLBACK');
      if (featureCallBack) {
        if (featureCallBack.data) {
          this.userForm.controls.featureCallBackUrl.setValue(featureCallBack.data.url);
        }
      }
    }
    this.getCompanies();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.userForm.setValidators([this.companyValidator]);
    this.userForm.updateValueAndValidity();
    if (this.userForm.valid) {
      if (this.userForm.value.isAdmin) {
        this.userForm.value['roles'] = this.user['roles'];
      } else {
        this.userForm.value['roles'] = ['ROLE_USER'];
      }
      this.dialogRef.close(this.userForm.value);
    }
  }

  changeFeature(event, feature) {
    if (event.checked) {
      feature.enabledAt = new Date();
    } else {
      feature.enabledAt = null;
    }

    this._user.toogleFeature(feature).subscribe(
      (result) => {
        this.createNotification('Modification enregistrée');
      }
    );
  }

  saveCallBackUrl(event, feature) {
    feature.data = {url: this.userForm.value.featureCallBackUrl};
    this._user.setFeatureCallBackUrl(feature).subscribe(
      (result) => {
        this.createNotification('Modification enregistrée');
      }
    );
  }

  getFeature(name) {
    const features = this.user.userPaidFeatures.filter((feature) => {
        return feature.feature.name === name;
    });
    if (features[0]) {
      return features[0];
    }
    return null;
  }

  getCompanies() {
    this._company.getCompanies({}, '', null, null, 0, 9999).subscribe(
      (companies) => {
        this.companies = companies['hydra:member'];
        this.companies_filtered = this.userForm.controls.company.valueChanges.pipe(
          startWith(''),
          map(agent => agent ? this.filterCompany(agent) : this.companies.slice())
        );
      }
    );
  }

  filterCompany(value: string): Company[] {
    if (typeof value === 'string') {
      const filterValue = value.toLocaleLowerCase();
      return this.companies.filter(company => {
        if (company.name) {
          return company.name.toLocaleLowerCase().includes(filterValue);
        }
        return false;
      });
    }
    return this.companies;
  }

  displayCompany(company?: Company) {
    return company.name;
  }

  createNotification(message) {

  }

  private companyValidator(group: FormGroup) {
    const company = group.controls.company;
    if (company.value instanceof Object || company.value === '') {
      return null;
    }
    company.setErrors({error: true});
    return {
      error: true
    };
  }

  generateApiKey(user) {
    let userCopy = Object.assign({}, user);
    userCopy.apiKey = this.makeid(32);
    delete userCopy.userPaidFeatures;
    this._user.saveUser(userCopy).subscribe((result) => {
      user.apiKey = userCopy.apiKey;
      this.createNotification('Clé API générée !');
    }, (err) => {
      this.createNotification(err.error['hydra:description']);
    });
  }

  makeid(length) {
    let result           = '';
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  close() {
    this.dialogRef.close();
  }

}
