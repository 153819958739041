import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {UserAreaService} from '../../Service/user-area.service';
import {UserAreaDataSource} from '../../DataSource/UserAreaDataSource';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {UserService} from '../../Service/user.service';
import {User} from '../../Model/User';
import {UserArea} from '../../Model/UserArea';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UserAreaDialogComponent} from './user-area-dialog/user-area-dialog.component';
import {NotificationService} from '../../Service/notification.service';

@Component({
  selector: 'app-user-area',
  templateUrl: './user-area.component.html',
  styleUrls: ['./user-area.component.scss']
})
export class UserAreaComponent implements OnInit, AfterViewInit {

  dataSource: UserAreaDataSource;
  length = 0;
  displayedColumns: string[] = ['id', 'name', 'admin', 'action'];
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild('usersAdminInput', {static: false}) usersAdminInput;

  allUsersAdmin: User[];
  usersAdmin: User[] = [];

  dialogRef: MatDialogRef<UserAreaDialogComponent>;

  perPage = 10;
  page = 1;
  maxPage;

  constructor(
    private _userArea: UserAreaService,
    private _user: UserService,
    public dialog: MatDialog,
    private notification: NotificationService,
  ) {
    this.getAdminUsers();
  }

  ngOnInit() {
    this.dataSource = new UserAreaDataSource(this._userArea);
    this.dataSource.lengthSubject.asObservable().subscribe(
      (length) => {
        this.length = length;
      }
    );
  }

  ngAfterViewInit() {
    this.loadUserAreaPage();

    // fromEvent(this.search_input.nativeElement,'keyup')
    //   .pipe(
    //     debounceTime(500),
    //     distinctUntilChanged(),
    //     tap(() => {
    //       this.paginator.pageIndex = 0;
    //       this.loadUserAreaPage();
    //     })
    //   )
    //   .subscribe();

    // reset the paginator after sorting
    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // on sort or paginate eventfs, load a new page
    // merge(this.sort.sortChange, this.paginator.page)
    //   .pipe(
    //     tap(() => this.loadUserAreaPage())
    //   )
    //   .subscribe();

  }

  loadUserAreaPage() {
    const filter = {};

    this.dataSource.loadUserArea(
      filter,
      // this.search_input.nativeElement.value,
      '',
      '',
      'asc',
      this.page - 1,
      this.perPage
    );
  }

  getAdminUsers() {
    this._user.getAllUsers({}, 'ROLE_ADMIN').subscribe(
      (users) => {
        this.allUsersAdmin = users['hydra:member'];
        this.allUsersAdmin = this.allUsersAdmin.filter((user: User) => {
          return !user.roles.includes('ROLE_SUPER_ADMIN');
        });
      }
    );
  }

  openDialog(userArea = null) {
    this.dialogRef = this.dialog.open(UserAreaDialogComponent, {
      disableClose: false,
      data: userArea,
      panelClass: 'modal-style',
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (!result.id) {
          delete result.id;
        }
        this._userArea.postUserArea(result).subscribe(
          (results) => {
            this.notification.send('success', 'Espace client édité');
            this.loadUserAreaPage();
          },
          (err) => {
            this.notification.send('error', err.error['hydra:description']);
          }
        );
      }
    });
  }

  remove(userAdmin, userArea: UserArea) {
    const index = userArea.usersAdmin.indexOf(userAdmin);

    if (index >= 0) {
      userArea.usersAdmin.splice(index, 1);
      this._userArea.editUserArea(userArea);
    }
  }

  selected(event, userArea: UserArea): void {
    console.log(event);
    const user = event as User;
    if (userArea.usersAdmin.indexOf(user) > 0) {
      return;
    }
    userArea.usersAdmin.push(user);

    if (!userArea.usersAdmin) {
      userArea.usersAdmin = [];
    }
    this._userArea.editUserArea(userArea);

  }

  resetSearch() {
    this.page = 1;
    this.loadUserAreaPage();
  }

  changePage(page) {
    this.page = page;
    this.loadUserAreaPage();
  }

  changePerPage(event) {
    this.perPage = event;
    this.resetSearch();
  }
}
