import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CompanyService} from '../../../Service/company.service';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {Company} from '../../../Model/Company';

@Component({
  selector: 'app-company-dialog',
  templateUrl: './company-dialog.component.html',
  styleUrls: ['./company-dialog.component.scss']
})
export class CompanyDialogComponent implements OnInit {

  company: Company;

  companyForm: FormGroup = new FormGroup({
    id: new FormControl('', []),
    name: new FormControl('', []),
    terms: new FormControl('', []),
    logo: new FormControl('', []),
    tokenOpenLog: new FormControl('', []),
  });

  constructor(
    public dialogRef: MatDialogRef<CompanyDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: Company,
    private _company: CompanyService,
    private notification: MatSnackBar,
  ) { }

  ngOnInit() {
    if (this.data) {
      this.company = this.data;
      this.companyForm.patchValue(this.company);
    }
  }

  closeDialog() {
      this.dialogRef.close();
  }

  onFileChange(event) {
    const control = this.companyForm.controls.logo as FormArray;
    control.clear();
    const file = event.target.files[0];
    if (file['type'] !== 'image/png') {
      this.companyForm.controls.logo.setErrors({
        type: true
      });
      return;
    }
    const filename = file['name'];
    const fileContent = file as Blob;

    control.push(new FormGroup({
      filename: new FormControl(filename, []),
      content: new FormControl(fileContent, []),
    }));
  }

  onSubmit() {
    this.dialogRef.close(this.companyForm.value);
  }

}
