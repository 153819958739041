import { Injectable } from '@angular/core';
import {Cacheable} from 'ngx-cacheable';
import {Subject} from 'rxjs';
import {ApiService} from './api.service';

const cacheBuster$ = new Subject<void>();

const CACHE_PARAM = {
  maxCacheCount: 10,
  cacheBusterObserver: cacheBuster$
};

@Injectable({
  providedIn: 'root'
})
export class UserAreaService {

  constructor(
    private _api: ApiService,
  ) { }

  @Cacheable(CACHE_PARAM)
  public getUserArea(filters = {}, search = '', sort = null, sortDirection = null, page = 0, pageSize = 50) {
    filters['page'] = page + 1;
    filters['itemsPerPage'] = pageSize;
    if (sort) {
      filters['order[' + sort + ']'] = sortDirection;
    }
    filters['search'] = search;
    return this._api.get('user_areas', filters);
  }

  public editUserArea(userArea) {
    const copy = Object.assign({}, userArea);
    copy.usersAdmin = [];
    userArea.usersAdmin.forEach((user) => {
      copy.usersAdmin.push(user['@id']);
    });
    return this._api.put('user_areas/' + userArea.id, copy);
  }

  public postUserArea(userArea) {
    if (userArea.id) {
      return this._api.put('user_areas/' + userArea.id, userArea);
    }
    return this._api.postRow('user_areas', userArea);
  }
}
