import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './Component/login/login.component';
import {ContractComponent} from './Component/contract/contract.component';
import {AuthGuardService} from './Service/auth-guard.service';
import {UserComponent} from './Component/user/user.component';
import {LoginEmailComponent} from './Component/login/login-email/login-email.component';
import {CompanyComponent} from './Component/company/company.component';
import {LoginEmailFormComponent} from './Component/login/login-email-form/login-email-form.component';
import {UserAreaComponent} from './Component/user-area/user-area.component';
import {SettingsComponent} from './Component/settings/settings.component';
import {ReportingComponent} from './Component/reporting/reporting.component';
import {ContactComponent} from './Component/contact/contact.component';
import {EditorComponent} from './Component/editor/editor.component';
import {StatisticsComponent} from './Component/statistics/statistics.component';
import {FileComponent} from './Component/file/file.component';
import {LibraryComponent} from './Component/library/library.component';
import {FileContractComponent} from './Component/file/file-contract/file-contract.component';
import {OpenlogsHashComponent} from './Component/openlogs-hash/openlogs-hash.component';


const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'login-email', component: LoginEmailFormComponent},
  {path: 'login-email/:token', component: LoginEmailComponent, data: {title: 'Login'}},
  {path: '', redirectTo: 'contract', pathMatch: 'full'},

  {path: 'contract', component: ContractComponent, canActivate: [AuthGuardService], data: {title: 'Documents'}},
  {path: 'user', component: UserComponent, canActivate: [AuthGuardService], data: {title: 'Utilisateurs'}},
  {path: 'company', component: CompanyComponent, canActivate: [AuthGuardService], data: {title: 'Entreprises'}},
  {path: 'userArea', component: UserAreaComponent, canActivate: [AuthGuardService], data: {title: 'Espace Client'}},
  {path: 'settings', component: SettingsComponent, canActivate: [AuthGuardService], data: {title: 'Parametres'}},
  {path: 'report', component: ReportingComponent, canActivate: [AuthGuardService], data: {title: 'Rapport'}},
  {path: 'contacts', component: ContactComponent, canActivate: [AuthGuardService], data: {title: 'Annuaire'}},
  {path: 'editor', component: EditorComponent, canActivate: [AuthGuardService], data: {title: 'Composeur'}},
  {path: 'statistics', component: StatisticsComponent, canActivate: [AuthGuardService], data: {title: 'Statistics BO'}},
  {path: 'library', component: LibraryComponent, canActivate: [AuthGuardService], data: {title: 'Bibliothèque'}},
  {path: 'files', component: FileComponent, canActivate: [AuthGuardService], data: {title: 'Mes fichiers'}},
  {path: 'contracts', component: FileContractComponent, canActivate: [AuthGuardService], data: {title: 'Mes contrats'}},
  {path: 'hash', component: OpenlogsHashComponent, canActivate: [AuthGuardService], data: {title: 'Openlogs hash'}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
